<template>
    <Dialog v-model:visible="plotStore.isEditDialogShown" modal header="Edit plot" :closable="false"
        :style="{ width: '50rem' }">
        <div class="grid">
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Plot ID</label>
                <InputText v-model="plotStore.plotToEdit.plotId" />
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Commodity</label>
                <Dropdown v-model="plotStore.plotToEdit.commodity" :options="COMMODITIES" class="w-full">
                </Dropdown>
                <div v-if="plotStore.plotToEdit.commodity === 'Wood'" class="flex flex-column gap-2 mt-2">
                    <label class="font-medium">Tree species</label>
                    <Dropdown v-model="selectedTreeSpecies" :options="TREES" optionLabel="tree" class="w-full"
                        placeholder="Select Tree Species">
                        <template #value="slotProps">
                            <span v-if="slotProps.value && slotProps.value.tree">{{ slotProps.value?.tree }} ({{
                                slotProps.value?.scientific }})</span>
                            <span v-else>
                                {{ slotProps.placeholder }}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div class="flex align-items-center">
                                <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Supplier</label>
                <Dropdown v-model="selectedSupplier" :options="supplierStore.allSuppliers" optionLabel="name"
                    class="w-full" showClear>
                </Dropdown>
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Producer name</label>
                <InputText v-model="plotStore.plotToEdit.producerName" />
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Additional information</label>
                <Textarea v-model="plotStore.plotToEdit.additionalInfo" rows="5" cols="30" autoResize />
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-2" @click="plotStore.hideEditDialog" />
            <Button label="Save changes" class="light-green-nadar" @click="updatePlot" :loading="isUpdating" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useAnalyticsAPI } from '../../../composables/useAnalyticsAPI';
import { useRoute } from 'vue-router';
import { usePlotStore } from "../../../stores/plot";
import { useSupplierStore } from '../../../stores/supplier';
import { COMMODITIES, TREES } from "../../../utils/constants";
import { usePlotRiskUtils } from './composables/usePlotRiskUtils';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const { refreshData } = usePlotRiskUtils();
const { patchPlot } = useAnalyticsAPI();
const toast = useToast();
const plotStore = usePlotStore();
const supplierStore = useSupplierStore();
const isUpdating = ref(false);

const selectedTreeSpecies = computed({
    get: () => {
        if (plotStore.plotToEdit?.treeSpecies) {
            return TREES.find(tree => tree.tree === plotStore.plotToEdit.treeSpecies.tree);
        }
        return null;
    },
    set: (value) => {
        plotStore.plotToEdit.treeSpecies = value ? value : null;
    }
});

const selectedSupplier = computed({
    get: () => {
        return supplierStore.allSuppliers.find(s => s._id === plotStore.plotToEdit?.supplier?._id);
    },
    set: (value) => {
        plotStore.plotToEdit.supplier = value ? { _id: value._id, name: value.name } : null;
    }
});

function validatePlotData() {
    if (!plotStore.plotToEdit.plotId) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: "Please enter a plot ID.", life: 3000 });
        return false;
    }

    if (!plotStore.plotToEdit.commodity) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: "Please select a commodity.", life: 3000 });
        return false;
    }

    if (plotStore.plotToEdit.commodity === 'Wood' && !plotStore.plotToEdit.treeSpecies) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: "Please select a tree species.", life: 3000 });
        return false;
    }

    return true;
}

async function updatePlot() {
    if (!validatePlotData()) {
        return;
    }

    const body = {
        plotId: plotStore.plotToEdit.plotId,
        commodity: plotStore.plotToEdit.commodity,
        supplier: plotStore.plotToEdit.supplier?._id || null,
        additionalInfo: plotStore.plotToEdit.additionalInfo,
        producerName: plotStore.plotToEdit.producerName,
        treeSpecies: plotStore.plotToEdit.treeSpecies
    };

    try {
        isUpdating.value = true;
        console.log(plotStore.plotToEdit)
        await patchPlot(plotStore.plotToEdit._id, body);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Plot successfully updated.', life: 3000 });
        plotStore.hideEditDialog();
        refreshData();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to update plot.", life: 3000 });
    } finally {
        isUpdating.value = false;
    }
}
</script>