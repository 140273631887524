<template>
    <div v-if="deviceStore.device !== 'mobile'" class="dashboard-layout">
        <Sidebar />
        <div class="content-container">
            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>
    </div>
    <div v-else>
        <router-view v-slot="{ Component }">
            <keep-alive include="MapPage">
                <component :is="Component" />
            </keep-alive>
        </router-view>
        <Navbar />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import Sidebar from '../components/layout/Sidebar.vue';
import Navbar from "../components/layout/Navbar.vue";
import { useDeviceStore } from '../stores/device';
import { useOrganizationStore } from '../stores/organization';
import { useUsersStore } from '../stores/users';
import * as Sentry from "@sentry/vue";


const usersStore = useUsersStore();
const toast = useToast();
const organizationStore = useOrganizationStore();
// Using the device store to manage and access the current device state.
const deviceStore = useDeviceStore();
// Computed Property:
// 'plotComponent' is a reactive property which will automatically update whenever
// 'deviceStore.device' changes. It determines which component to use - 
// MobileCreatePlot if the current device is 'mobile', or DesktopCreatePlot otherwise.
const deviceComponent = computed(() => {
    return deviceStore.device === 'mobile' ? Navbar : Sidebar;
});


async function fetchData() {
    await usersStore.fetchCurrentUser();

    if (usersStore.error && deviceStore.device !== "mobile") {
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to fetch profile.', life: 3000 });
    } else {
        Sentry.setUser({ email: usersStore.currentUser?.email });
    }
}

fetchData();
</script>

<style>
.dashboard-layout {
    display: flex;
    height: 100vh;
    /* Take full height of the viewport */
    background-color: #ffffff;
}

.content-container {
    flex: 1;
    /* Takes the remaining space */
    overflow: auto;
    /* Adds scroll to the content if needed */
}

.mobile-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #f9fafc;
}

.sidebar-container {
    width: 150px;
}
</style>