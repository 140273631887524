<template>
    <Button icon="pi pi-sync" @click="refreshData" :loading="plotsStore.isTableLoading" />
</template>


<script setup>
import { usePlotsStore } from "../../stores/plots";
import { usePlotsUtils } from "../../components/plots/composables/usePlotsUtils";


const { refreshData } = usePlotsUtils();
const plotsStore = usePlotsStore();
</script>