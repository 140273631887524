<template>
  <router-view></router-view>
  <SessionExpiredDialog />
  <ErrorTemplateDialog />
  <Toast position="top-right" group="headless">
    <template #container="{ message, closeCallback }">
      <section :class="[
        'flex p-3 gap-3 w-full',
        {
          'bg-green-50': message.severity === 'success',
          'bg-blue-50': message.severity === 'info',
          'bg-orange-50': message.severity === 'warn',
          'bg-red-50': message.severity === 'error'
        }
      ]" style="border-radius: 6px">
        <i :class="[
          'text-2xl',
          {
            'pi pi-check-circle text-green-700': message.severity === 'success',
            'pi pi-info-circle text-blue-700': message.severity === 'info',
            'pi pi-exclamation-circle text-orange-700': message.severity === 'warn',
            'pi pi-times-circle text-red-700': message.severity === 'error'
          }
        ]"></i>
        <div class="flex flex-column gap-2 w-full">
          <p :class="[
            'm-0 font-semibold text-base',
            {
              'text-green-900': message.severity === 'success',
              'text-blue-900': message.severity === 'info',
              'text-orange-900': message.severity === 'warn',
              'text-red-900': message.severity === 'error'
            }
          ]">{{ message.summary }}</p>
          <p :class="[
            'm-0 text-base',
            {
              'text-green-900': message.severity === 'success',
              'text-blue-900': message.severity === 'info',
              'text-orange-900': message.severity === 'warn',
              'text-red-900': message.severity === 'error'
            }
          ]">{{ message.detail }}</p>
        </div>
        <Button icon="pi pi-times" text :class="[
          'p-0 align-self-start hover:bg-transparent',
          {
            'text-green-900': message.severity === 'success',
            'text-blue-900': message.severity === 'info',
            'text-orange-900': message.severity === 'warn',
            'text-red-900': message.severity === 'error'
          }
        ]" @click="closeCallback" />
      </section>
    </template>
  </Toast>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import SessionExpiredDialog from './components/common/SessionExpiredDialog.vue';
import ErrorTemplateDialog from './components/common/ErrorTemplateDialog.vue';


const toast = useToast();
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.p-rowgroup-header {
  background-color: #efefef;
  font-weight: bold;
}

/* Custom styles for PrimeVue DataTable selected row */
.p-datatable .p-datatable-tbody>tr.p-highlight {
  background-color: #ebfdee !important;
  /* Change this color to your desired background color */
  color: #232323 !important;
}

.p-card {
  border: 1px solid #d8d8d8;
  box-shadow: none !important;
  /* Darker border color */
}

.light-green-nadar {
  background-color: #A1E888;
  border-color: #A1E888;
  color: #151615;
}

.light-green-nadar:hover {
  background-color: #89e269;
}

.light-green-nadar-color {
  color: #A1E888;
}

/*
.p-dropdown {
  border-radius: 20px;
}

.p-multiselect {
  border-radius: 20px;
}

.p-inputtext {
  border-radius: 20px;

}
*/
</style>
