<template>
    <div class="flex justify-content-between">
        <span class="font-medium text-2xl">User Management</span>
        <div>
            <Button label="Create user" class="mr-2" @click="showAddUserDialog"></Button>
            <Button icon="pi pi-sync" @click="usersStore.fetchAllUsers" :loading="usersStore.isAllUsersLoading" />
        </div>
    </div>

    <div class="mt-3">
        <Card>
            <template #content>
                <DataTable :value="usersStore.allUsers" size="small">
                    <template #empty>
                        <div v-if="!usersStore.isAllUsersLoading"
                            class="flex flex-column align-items-center justify-content-center select-none">
                            <img :src="placeholder" width="200px">
                            <span>No users found. Please add new users to see them listed here.</span>
                        </div>
                        <div v-else>
                            <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                        </div>
                    </template>
                    <Column field="name" header="Name">
                        <template #body="{ data }">
                            {{ data.firstName }} {{ data.lastName }}
                        </template>
                    </Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="lastLogin" header="Last login">
                        <template #body="{ data }">
                            {{ formatDate(data.lastLogin) }}
                        </template>
                    </Column>
                    <Column field="role" header="Role">
                        <template #body="{ data }">
                            {{ formatRole(data.role) }}
                        </template>
                    </Column>
                    <Column field="" header="Actions">
                        <template #body="{ data }">
                            <Button icon="pi pi-user-edit" class="mr-2" outlined size="small"
                                @click="showEditUserDialog(data)" />
                            <Button icon="pi pi-times-circle" outlined size="small" @click="
                                showDeleteUserDialog(data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>

    <Dialog v-model:visible="isDeleteUserDialogShown" modal header="Delete user?" :closeOnEscape="false"
        :closable="false" :style="{ width: '30rem' }">
        <span>This will delete user </span>
        <span class="font-medium"> {{ selectedUser.email }}. </span>
        <span>You cannot undo this action.</span>

        <template #footer>
            <Button label="Cancel" class="mr-3" severity="secondary" @click="hideDeleteUserDialog" />
            <Button label="Delete" severity="danger" @click="removeUser" :loading="usersStore.isCurrentUserLoading" />
        </template>
    </Dialog>

    <Dialog v-model:visible="isAddUserDialogShown" modal header="Create user" :closeOnEscape="false" :closable="false"
        :style="{ width: '50rem' }" @update:visible="(visible) => !visible && closeAndClearAddUserDialog()">
        <Stepper linear>
            <StepperPanel header="User">
                <template #content="{ nextCallback }">
                    <div class="grid">
                        <div class="flex flex-column col-6">
                            <label>First name <span style="color: red">*</span></label>
                            <InputText v-model="firstName" />
                        </div>
                        <div class="flex flex-column col-6">
                            <label>Last name <span style="color: red">*</span></label>
                            <InputText v-model="lastName" />
                        </div>
                        <div class="flex flex-column col-12">
                            <label>Email <span style="color: red">*</span></label>
                            <InputText v-model="email" />
                        </div>
                        <div class="flex flex-column col-12">
                            <label>Password <span style="color: red">*</span></label>
                            <InputText v-model="password" />
                        </div>
                        <div class="flex pt-4 justify-content-end col-12">
                            <Button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                @click="checkIfUserFormIsFilledOut(nextCallback)" />
                        </div>
                    </div>
                </template>
            </StepperPanel>
            <StepperPanel header="Roles and permission">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column">
                        <span class="text-lg">Define User Role</span>
                        <div class="grid flex justify-content-center mt-3">
                            <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                                @click="selectRole('EDITOR')"
                                :class="selectedRole === 'EDITOR' ? 'border-primary' : 'surface-border'">
                                <span class="padding">Editor</span>
                            </div>
                            <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                                @click="selectRole('VIEWER')"
                                :class="selectedRole === 'VIEWER' ? 'border-primary' : 'surface-border'">
                                <span class="padding">Viewer</span>
                            </div>
                            <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                                @click="selectRole('MAPPER')"
                                :class="selectedRole === 'MAPPER' ? 'border-primary' : 'surface-border'">
                                <span class="padding">Mapper</span>
                            </div>
                        </div>
                        <div class="my-3">
                            <Message v-if="selectedRole === 'EDITOR'" :closable="false">{{ editorExplanation }}
                            </Message>
                            <Message v-if="selectedRole === 'VIEWER'" :closable="false">{{ viewerExplanation }}
                            </Message>
                            <Message v-if="selectedRole === 'MAPPER'" :closable="false">{{ mapperExplanation }}
                            </Message>
                        </div>

                        <div v-if="selectedRole === 'MAPPER'">
                            <span class="text-lg">Batch for Mapper Role</span>
                            <Dropdown v-model="selectedBatch" :options="batchStore.allBatches" optionLabel="label"
                                placeholder="Select one" class="w-full" />
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                            <Button label="Add User" :loading="usersStore.isAllUsersLoading" class="light-green-nadar"
                                icon="pi pi-plus" @click="createUser" />
                        </div>
                    </div>
                </template>
            </StepperPanel>
        </Stepper>
    </Dialog>

    <Dialog v-model:visible="isEditUserDialogShown" modal header="Edit User" :style="{ width: '50rem' }">
        <div class="flex flex-column">
            <span class="text-lg">Define User Role</span>
            <div class="grid flex justify-content-center mt-3">
                <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                    @click="selectRole('EDITOR')"
                    :class="selectedRole === 'EDITOR' ? 'border-primary' : 'surface-border'">
                    <span class="padding">Editor</span>
                </div>
                <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                    @click="selectRole('VIEWER')"
                    :class="selectedRole === 'VIEWER' ? 'border-primary' : 'surface-border'">
                    <span class="padding">Viewer</span>
                </div>
                <div class="col-2 flex justify-content-center align-items-center border-round border-2 cursor-pointer square m-1"
                    @click="selectRole('MAPPER')"
                    :class="selectedRole === 'MAPPER' ? 'border-primary' : 'surface-border'">
                    <span class="padding">Mapper</span>
                </div>
            </div>
            <div class="mt-3">
                <Message v-if="selectedRole === 'EDITOR'" :closable="false">{{ editorExplanation }}
                </Message>
                <Message v-if="selectedRole === 'VIEWER'" :closable="false">{{ viewerExplanation }}
                </Message>
                <Message v-if="selectedRole === 'MAPPER'" :closable="false">{{ mapperExplanation }}
                </Message>
            </div>

            <div v-if="selectedRole === 'MAPPER'">
                <span class="font-medium">Attached Batch *</span>
                <Dropdown v-model="selectedBatch" :options="batchStore.allBatches" optionLabel="label"
                    placeholder="Select" class="w-full" />
            </div>

            <div class="flex pt-4 justify-content-end">
                <Button label="Save" class="light-green-nadar" :loading="usersStore.isCurrentUserLoading"
                    @click="updateUser" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from "vue";
import { useUsersStore } from "../stores/users";
import { useBatchStore } from "../stores/batch";
import { useIdentityService } from '../composables/useIdentityService';
import { useToast } from "primevue/usetoast";
import placeholder from "../assets/placeholder_dataImport.svg"
import * as Sentry from "@sentry/vue";


const { deleteUser, postUser, patchUser } = useIdentityService();
const toast = useToast();
const usersStore = useUsersStore();
const batchStore = useBatchStore();
const isEditUserDialogShown = ref(false);
const isDeleteUserDialogShown = ref(false);
const isAddUserDialogShown = ref(false);
const selectedUser = ref();
const selectedBatch = ref();
const email = ref();
const password = ref();
const firstName = ref();
const lastName = ref();
const selectedRole = ref('EDITOR');
const editorExplanation = ref("Editors have read write acceess, allowing them to view, create, delete, and update content across most sections of the platform, except organization information and user management.");
const viewerExplanation = ref("Viewers have read-only access, enabling them to view content across the application, excluding organization information and user management.");
const mapperExplanation = ref("Mappers can only create, but never delete or export their own plots in the space of the assigned batch.")


function showDeleteUserDialog(data) {
    selectedUser.value = data;
    isDeleteUserDialogShown.value = true;
}

function hideDeleteUserDialog() {
    isDeleteUserDialogShown.value = false;
}

function showEditUserDialog(data) {
    for (const batch of batchStore.allBatches) {
        if (batch._id === data.assignedBatch) {
            selectedBatch.value = batch;
        }
    }

    selectedUser.value = data;
    selectedRole.value = selectedUser.value.role;
    isEditUserDialogShown.value = true;
}

function hideEditUserDialog() {
    isEditUserDialogShown.value = false;
}

function showAddUserDialog() {
    isAddUserDialogShown.value = true;
}

function closeAndClearAddUserDialog() {
    isAddUserDialogShown.value = false;
    firstName.value = null;
    lastName.value = null;
    email.value = null;
    password.value = null;
    selectedRole.value = "EDITOR";
    selectedBatch.value = null;
}

async function updateUser() {
    if (selectedRole.value === 'MAPPER' && !selectedBatch.value?._id) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing field', detail: 'Please select a batch.', life: 5000 });
        return;
    }

    const userData = {
        role: selectedRole.value,
        assignedBatch: selectedRole.value === "MAPPER" ? selectedBatch.value._id : null
    }

    const uoid = selectedUser.value._id;

    try {
        usersStore.isCurrentUserLoading = true;
        await patchUser(uoid, userData);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'User successfully updated.', life: 5000 });
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to update uer.', life: 5000 });
        return;
    } finally {
        usersStore.isCurrentUserLoading = false;
    }

    fetchUserManagement();
    hideEditUserDialog();
}

function checkIfUserFormIsFilledOut(nextCallback) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedFirstName = firstName.value?.trim();
    const trimmedLastName = lastName.value?.trim();
    const trimmedEmail = email.value?.trim();
    const trimmedPassword = password.value?.trim();

    if (!trimmedFirstName) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing Fields', detail: "First name is required.", life: 3000 });
        return false;
    }

    if (!trimmedLastName) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing Fields', detail: "Last name is required.", life: 3000 });
        return false;
    }

    if (!trimmedEmail || !emailRegex.test(trimmedEmail)) {
        toast.add({ group: "headless", severity: 'info', summary: 'Invalid Email', detail: "Please provide a valid email address.", life: 3000 });
        return false;
    }

    if (!trimmedPassword || trimmedPassword.length < 8) {
        toast.add({ group: "headless", severity: 'info', summary: 'Weak Password', detail: "Password must be at least 8 characters long.", life: 3000 });
        return false;
    }

    if (!/[A-Za-z]/.test(trimmedPassword) || !/\d/.test(trimmedPassword)) {
        toast.add({ group: "headless", severity: 'info', summary: 'Weak Password', detail: "Password must contain both letters and numbers.", life: 3000 });
        return false;
    }

    nextCallback();
}

function selectRole(role) {
    selectedRole.value = role;
}

async function createUser() {
    if (selectedRole.value === 'MAPPER' && !selectedBatch.value?._id) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing field', detail: 'Please select a batch.', life: 5000 });
        return;
    }

    const userData = {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        role: selectedRole.value,
    }

    if (selectedRole.value === "MAPPER") {
        userData.assignedBatch = selectedBatch.value._id
    }

    try {
        usersStore.isAllUsersLoading = true;
        const response = await postUser(userData);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'User successfully created.', life: 5000 });
        closeAndClearAddUserDialog();
        fetchUserManagement();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to create user.', life: 5000 });
    } finally {
        usersStore.isAllUsersLoading = false;
    }
}

async function removeUser() {
    try {
        usersStore.isCurrentUserLoading = true;
        await deleteUser(selectedUser.value._id);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'User successfully deleted.', life: 5000 });
        hideDeleteUserDialog();
        fetchUserManagement();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to delete user.', life: 5000 });
    } finally {
        usersStore.isCurrentUserLoading = false;
    }
}

async function fetchUserManagement() {
    try {
        await usersStore.fetchAllUsers();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to fetch users.', life: 5000 });
        return;
    }

    try {
        await batchStore.fetchAllBatches();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to fetch batches.', life: 5000 });
        return;
    }
}

const formatDate = (dateString) => {
    if (!dateString) {
        return ""
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};

function getSeverity(status) {
    switch (status) {
        case "ACTIVE":
            return "success"
        case "DISABLED":
            return "danger"
    }
}

function formatRole(role) {
    switch (role) {
        case "MAPPER":
            return "Mapper"
        case "ADMIN":
            return "Admin"
        case "VIEWER":
            return "Viewer"
        case "EDITOR":
            return "Editor"
        case "CUSTOM":
            return "Custom"
    }
}

fetchUserManagement();
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    overflow: auto;
    /* Prevents scroll outside the containers */
    padding: 1rem;
}


.square {
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
}
</style>