<template>
    <div class="container">
        <div class="flex align-items-center justify-content-center" style="height: 70vh;">
            <div v-if="route.query.mode === 'resetPassword'" class="flex justify-content-center card-wrapper">
                <div class="card-outlined border-round-lg p-4 shadow-4">
                    <h1>New Password</h1>
                    <span class="p-float-label mt-6">
                        <InputText class="w-full" v-model="password" type="password" />
                        <label for="firstName">Password</label>
                    </span>
                    <Button class="w-full mt-5 p-3" label="Change Password" @click="handleChangePasword"
                        :loading="isLoading" />
                </div>
            </div>
            <div v-if="route.query.mode === 'recoverEmail'" class="flex justify-content-center card-wrapper">
                <div class="card-outlined border-round-lg p-4 shadow-4">
                    <h1>Email Recovery</h1>
                    <p>If you requested to recover your email, please confirm the action below.</p>
                    <Button class="w-full mt-5 p-3" label="Confirm Email Recovery" @click="handleEmailRecovery" />
                </div>
            </div>
            <div class="links">
                <a href="https://www.nadar.earth/imprint" class="impressum">Imprint</a>
                <a href="#" class="impressum" @click.prevent="goToPrivacyPolicy">Privacy Policy</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getAuth, confirmPasswordReset, applyActionCode, checkActionCode } from 'firebase/auth';
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";

const toast = useToast();
const route = useRoute();
const router = useRouter();
const password = ref("");
const isLoading = ref(false);


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function handleEmailRecovery() {
    const auth = getAuth();
    const oobCode = route.query.oobCode;

    try {
        await applyActionCode(auth, oobCode);
    } catch (error) {
        Sentry.captureException(error);
        let errorMessage;
        switch (error.code) {
            case 'auth/expired-action-code':
                errorMessage = 'The recovery link has expired. Please request a new recovery email.';
                break;
            case 'auth/invalid-action-code':
                errorMessage = 'The recovery link is invalid. It may have already been used, or it might be malformed.';
                break;
            case 'auth/user-disabled':
                errorMessage = 'The user account associated with this email has been disabled.';
                break;
            case 'auth/user-not-found':
                errorMessage = 'No user account found associated with this email.';
                break;
            case 'auth/invalid-email':
                errorMessage = 'The email address is improperly formatted.';
                break;
            default:
                errorMessage = 'An unknown error occurred. Please try again later.';
        }
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        return;
    }

    toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: "Your email has been successfully recovered. Please log in again.", life: 5000 });
    await sleep(2000)
    router.push("/");
}


async function handleChangePasword() {
    isLoading.value = true;
    const auth = getAuth();
    const oobCode = route.query.oobCode
    try {
        await confirmPasswordReset(auth, oobCode, password.value);
    } catch (error) {
        let errorMessage;
        switch (error.code) {
            case 'auth/expired-action-code':
                errorMessage = 'The password reset link has expired. Please request a new one.';
                break;
            case 'auth/invalid-action-code':
                errorMessage = 'The password reset link is invalid. It may have already been used.';
                break;
            case 'auth/user-disabled':
                errorMessage = 'Your account has been disabled. Please contact support.';
                break;
            case 'auth/user-not-found':
                errorMessage = 'No account found with this email. Please check and try again.';
                break;
            case 'auth/weak-password':
                errorMessage = 'The password is too weak. Please use a stronger password.';
                break;
            default:
                errorMessage = 'An unknown error occurred. Please try again later.';
        }
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        return;
    } finally {
        isLoading.value = false;
    }

    toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: "Password has been changed.", life: 5000 });
    await sleep(2000)
    router.push("/")
}
</script>


<style scoped>
.links {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 2vh;
    margin-right: 4vw;
}

.impressum {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    display: inline-block;
    margin-right: 20px;
}

.card-wrapper {
    margin: auto;
    /* Center the card in the available space */
    max-width: 400px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.card-outlined {
    background-color: #FBFBFB;
    z-index: 100;
    text-align: center;
    margin: auto;
    /* Center the card in the available space */
    max-width: 600px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.logo-above {
    position: relative;
    z-index: 2;
    /* Higher than the z-index of AnimationComponent */
}

.forgot-password-button {
    text-decoration: underline;
    display: inline-block;
    /* Aligns the button text to center */
    margin: 0 auto;
    /* Additional centering for the button */
}

.container {
    min-height: 100vh;
    /* Ensure it covers the full viewport height */
    width: 100%;
    /* Ensure it covers the full viewport width */
    /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    background-color: #f3f6f7;
}
</style>
