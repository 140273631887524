<template>
    <div class="batch-container">
        <div class="align-items-center text-color-secondary">
            <i class="pi pi-arrow-left mr-2 my-2 cursor-pointer" @click="goToBatches"></i>
            <span class="cursor-pointer" @click="goToBatches">Back to Batches</span>
        </div>
        <TabView @tab-change="onTabChange">
            <TabPanel header="Plots">
                <div class="grid">
                    <div class="col-7">
                        <PlotsTable />
                    </div>
                    <div class="col-5" style="height: calc(100vh - 120px)">
                        <PlotsMap :isActive="activeTab === 0" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="TRACES info">
                <div class="grid">
                    <div class="col-12">
                        <TracesTab />
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </div>

    <RiskDetectionFailureDialog />
    <RiskDetectionSuccessDialog />
    <EditPlotDialog />
    <DeletePlotsDialog />
</template>

<script setup>
import { onMounted, ref, provide, onUnmounted } from 'vue';
import PlotsMap from '../components/batches/plots/PlotsMap.vue';
import PlotsTable from '../components/batches/plots/PlotsTable.vue';
import { useRouter } from 'vue-router';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import RiskDetectionFailureDialog from '../components/batches/plots/RiskDetectionFailureDialog.vue';
import RiskDetectionSuccessDialog from '../components/batches/plots/RiskDetectionSuccessDialog.vue';
import TracesTab from '../components/batches/TracesTab.vue';
import { usePlotStore } from '../stores/plot';
import EditPlotDialog from '../components/batches/plots/EditPlotDialog.vue';
import DeletePlotsDialog from '../components/batches/plots/DeletePlotsDialog.vue';


const router = useRouter();
const plotStore = usePlotStore();
const activeTab = ref(0); // 0: Overview, 1: Verification, 2: Deforestation Check
let batchMap = ref();
const draw = new MapboxDraw({
    displayControlsDefault: false,
});


provide("batchMap", batchMap);
provide("draw", draw);

function goToBatches() {
    router.push({ name: 'batches' });
};

function onTabChange(event) {
    activeTab.value = event.index;
}

onMounted(() => {
    console.log("onMounted BatchPage")
})

onUnmounted(() => {
    console.log("onUnmounted BatchPage")
    plotStore.$reset()
})
</script>


<style>
.p-tabview-nav .p-tabview-ink-bar {
    height: 2px;
    background-color: #A1E888;
}

.batch-container {
    display: flex;
    flex-direction: column;
    /* Adjust as needed based on your app's layout */
    overflow: hidden;
    /* Prevent overflow issues */
    padding: 1rem;
    box-sizing: border-box;
}
</style>