<template>
    <Dialog v-model:visible="supplierStore.isDeleteDialogShown" modal header="Delete supplier?" :closeOnEscape="false"
        :closable="false" :style="{ width: '30rem' }">
        <span>Delete <span class="font-medium">{{ supplierStore.supplierToDelete.name }}</span>? This action cannot be
            undone.</span>
        <template #footer>
            <Button label="Cancel" class="mr-2" severity="secondary" @click="supplierStore.hideDeleteDialog"></Button>
            <Button label="Delete" severity="danger" @click="handleDeleteSupplier" :loading="isDeleting"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useSupplierStore } from '../../stores/supplier';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import * as Sentry from "@sentry/vue";


const { deleteSupplier } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const toast = useToast();
const isDeleting = ref(false);


async function handleDeleteSupplier() {
    try {
        isDeleting.value = true;
        await deleteSupplier(supplierStore.supplierToDelete._id);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Supplier successfully deleted.', life: 3000 });
        supplierStore.fetchAllSuppliers();
        supplierStore.hideDeleteDialog();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to delete supplier.', life: 3000 });
    } finally {
        isDeleting.value = false;
    }
}
</script>