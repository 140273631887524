<template>
  <div class="duediligence-container">
    <div class="flex justify-content-between align-items-center">
      <span class="font-medium text-2xl">Due Diligence Statements</span>
      <div class="flex justify-content-end">
        <Button label="Create statement" @click="handleCreateStatement" class="mr-2" />
        <Button icon="pi pi-sync" @click="fetchStatements" :loading="dueDiligenceStore.isStatementsLoading" />
      </div>
    </div>

    <div class="mt-3">
      <InlineMessage severity="info" class="w-full">Due diligence statement creation may fail as we are using TRACES
        alpha API, which undergoes frequent changes.</InlineMessage>
      <StatementTable />
    </div>
    <CreateStatementStepper />
  </div>
</template>

<script setup>
import CreateStatementStepper from '../components/dueDiligenceStatement/CreateStatementStepper.vue';
import StatementTable from '../components/dueDiligenceStatement/StatementTable.vue';
import { useDueDiligenceStore } from '../stores/duediligence';
import * as Sentry from "@sentry/vue";


const dueDiligenceStore = useDueDiligenceStore();


async function fetchStatements() {
  try {
    await dueDiligenceStore.fetchDueDiligenceStatements();
  } catch (error) {
    Sentry.captureException(error);
    console.log(error)
    toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to load Statements.", life: 3000 });
  }
}

function handleCreateStatement() {
  dueDiligenceStore.resetStepper();
  dueDiligenceStore.isCreateStatementDialogShown = true;
}
</script>


<style scoped>
.duediligence-container {
  flex: 1;
  /* Adjust this value to change width relative to map-container */
  overflow: auto;
  /* Allows scrolling within the container if content overflows */
  padding: 1rem;
  box-sizing: border-box;
}
</style>