<template>
    <div class="sidebar flex justify-content-center">
        <div class="flex flex-column h-full sidebar">
            <div class="flex justify-content-center mb-4" style="padding-top: 1.1rem;">
                <!-- Centered logo container -->
                <img alt=" logo" src="@/assets/nadarLogoBlack.png" height="19" />
            </div>
            <div class="overflow-y-auto p-2">
                <li>
                    <a v-ripple @click="pushPlots" :class="['nav-link', { 'active': activeLinks.includes('plots') }]"
                        class="nav-link select-none cursor-pointer">
                        <span class="font-bold text-xs">Plots</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="pushBatches"
                        :class="['nav-link', { 'active': activeLinks.includes('batches') }]"
                        class="nav-link select-none cursor-pointer">
                        <span class="font-bold text-xs">Batches</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="pushSupplierList"
                        :class="['nav-link', { 'active': activeLinks.includes('suppliers') }]"
                        class="nav-link select-none cursor-pointer">
                        <span class="font-bold text-xs">Suppliers</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="pushDueDiligence"
                        :class="['nav-link', { 'active': activeLinks.includes('duediligence') }]"
                        class="nav-link select-none cursor-pointer">
                        <span class="font-bold text-xs">Due Diligence</span>
                    </a>
                </li>
            </div>
            <div class="mt-auto p-2">
                <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                <li>
                    <a v-ripple @click="pushHelpCenter" class="nav-link select-none cursor-pointer">
                        <i class="pi pi-question-circle mr-2" style="font-size: 0.8rem"></i>
                        <span class="font-bold text-xs">Help Center</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="pushSupport" class="nav-link select-none cursor-pointer">
                        <i class="pi pi-envelope mr-2" style="font-size: 0.8rem"></i>
                        <span class="font-bold text-xs">Support</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="pushSettings"
                        :class="['nav-link', { 'active': activeLinks.includes('settings') }]"
                        class="nav-link select-none cursor-pointer">
                        <i class="pi pi-cog mr-2" style="font-size: 0.8rem"></i>
                        <span class="font-bold text-xs">Settings</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="fetchSignOut" class="nav-link select-none cursor-pointer">
                        <i class="pi pi-sign-out mr-2" style="font-size: 0.8rem"></i>
                        <span class="font-bold text-xs">Logout</span>
                    </a>
                </li>
            </div>
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useIdentityService } from '../../composables/useIdentityService';
import { useToast } from 'primevue/usetoast';
import { HELP_CENTER_LINK, SUPPORT_LINK } from '../../utils/constants';


const { postLogoutUser } = useIdentityService();
const toast = useToast();
const router = useRouter();
const route = useRoute();
const routeHistory = ref([]);
const activeLinks = ref([]);
const isLoading = ref(false);
const isLoggingOut = ref(false);


watch(route, () => {
    determineActiveLink();
})

function pushDataImport() {
    activeLinks.value = ['import', 'dataCollection'];
    router.push({ name: 'import' });
}

function pushBatches() {
    activeLinks.value = ['batches'];
    router.push({ name: "batches" });
}

function pushPlots() {
    activeLinks.value = ['plots'];
    router.push({ name: 'plots' });
}

function pushSupplierList() {
    activeLinks.value = ['suppliers'];
    router.push({ name: "suppliers" });
}

function pushDueDiligence() {
    activeLinks.value = ['duediligence'];
    router.push({ name: 'duediligence' });
}

function pushHelpCenter() {
    window.open(HELP_CENTER_LINK, '_blank')
}

function pushSupport() {
    window.open(SUPPORT_LINK, '_blank')
}

function pushSettings() {
    activeLinks.value = ['settings'];
    router.push({ name: 'settings' });
}

async function fetchSignOut() {
    if (!isLoggingOut.value) {
        isLoggingOut.value = true;
        try {
            await postLogoutUser();
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        } finally {
            isLoggingOut.value = false;
            router.push({ name: 'login' })
        }
    }
}

// Function to update the route history
function updateRouteHistory() {
    if (route.fullPath !== routeHistory.value[routeHistory.value.length - 1]) {
        routeHistory.value.push(route.fullPath);
    }
}

// Set up route history tracking on component mount
onMounted(() => {
    updateRouteHistory();
    router.afterEach(() => {
        updateRouteHistory();
    });
});

function determineActiveLink() {
    const path = route.path;

    if (path === "/suppliers") {
        activeLinks.value = ['suppliers'];
    } else if (path === "/plots") {
        activeLinks.value = ['plots'];
    } else if (path === "/duediligence") {
        activeLinks.value = ['duediligence'];
    } else if (path === "/profile") {
        activeLinks.value = ['profile'];
    } else if (path === "/user-management") {
        activeLinks.value = ['user-management'];
    } else if (path === "/organization") {
        activeLinks.value = ['organization'];
    }
}

determineActiveLink();
</script>


<style>
.sidebar {
    border-right: 0.1px solid #d8d8d8;
    /* Sidebar background color */
    width: 160px;
    /* Sidebar width */
}

.sidebar ul,
.sidebar li {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
}

.sidebar li {
    margin-bottom: 10px;
    /* Add spacing between list items */
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 12px;
    /* Adjust padding as needed */
    color: #000000;
    /* Default text color */
    border-radius: 5px;
    /* Smooth transition for background color change */
}

/* Hover state */
.nav-link:hover {
    background-color: #ffffff1a;
    /* Custom green color for hover */
}

/* Active state styling */
.nav-link.active {
    /*background-color: #FF6347;*/
    /* Custom red color for active state */
    background-color: #A1E888;
    /*border-radius: 20px;*/
    color: #000000;
    /* Ensuring text color is visible */
}
</style>