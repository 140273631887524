<template>
    <div class="flex flex-column">
        <div class="field">
            <label>Batch <span>*</span></label>
            <SelectButton v-model="batchSelectionMode" :options="batchModeOptions" class="mb-2 w-full"
                :allowEmpty="false" @change="handleBatchModeChange" />
            <div v-if="batchSelectionMode === 'Select existing'" class="mt-2">
                <Dropdown v-model="selectedBatch" :options="prettyBatches" optionLabel="label" placeholder="Select"
                    class="w-full">
                </Dropdown>
            </div>
            <div v-else class="mt-2">
                <InputText v-model="newBatchLabel" placeholder="Enter new batch label" class="w-full" />
            </div>
        </div>
        <div class="field">
            <label>Commodity *</label>
            <Dropdown v-model="selectedCommodity" :options="COMMODITIES" placeholder="Select" class="w-full"
                @change="resetTreeSpecies">
            </Dropdown>
            <small>Select a commodity for all plots within the imported file.
            </small>
        </div>
        <div v-if="selectedCommodity === 'Wood'" class="field">
            <span>Tree Species *</span>
            <Dropdown v-model="selectedTreeSpecies" :options="TREES" optionLabel="tree" placeholder="Select"
                class="w-full" showClear>
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.tree }} ({{ slotProps.value.scientific }})</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})</div>
                    </div>
                </template>
            </Dropdown>
            <small>Select a supplier for all plots within the imported file.
            </small>
        </div>
        <div class="field">
            <span>Supplier</span>
            <Dropdown v-model="selectedSupplier" :options="supplierStore.allSuppliers" optionLabel="name"
                placeholder="Select" class="w-full mt-2" showClear />
            <small>Select a supplier for all plots within the imported file.
            </small>
        </div>
    </div>
    <div class="flex pt-4 justify-content-between">
        <Button label="Back" severity="secondary" @click="props.prevCallback" />
        <Button label="Import Plots" @click="handleSavePlotSettings" :loading="isImportPlotsLoading" />
    </div>
</template>

<script setup>
import { ref, toRaw, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { useDataImportStore } from "../../stores/dataImport";
import { useSupplierStore } from "../../stores/supplier";
import { TREES, COMMODITIES } from "../../utils/constants";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import { usePlotsUtils } from "../plots/composables/usePlotsUtils";
import { useBatchStore } from "../../stores/batch";
import * as Comlink from 'comlink';
import * as Sentry from "@sentry/vue";


const props = defineProps({
    prevCallback: {
        type: Function,
        required: true
    }
});

const { postCreatePlotsWithImport, getPollStatus, getAllFiles } = useAnalyticsAPI();
const { refreshData } = usePlotsUtils();
const batchStore = useBatchStore();
const dataImportStore = useDataImportStore();
const supplierStore = useSupplierStore();
const toast = useToast();
const selectedSupplier = ref();
const selectedCommodity = ref();
const selectedTreeSpecies = ref();
const workerInstance = ref();
const isImportPlotsLoading = ref(false);
const batchSelectionMode = ref('Select existing'); // Default to select mode
const batchModeOptions = ref(['Select existing', 'Create new']);
const selectedBatch = ref();
const newBatchLabel = ref();
const prettyBatches = ref([]);


function resetTreeSpecies() {
    selectedTreeSpecies.value = null;
}

function handleBatchModeChange(event) {
    const newMode = event.value;

    if (newMode === 'Select existing') {
        // Clear the new batch label when switching to select mode
        newBatchLabel.value = null;
    } else {
        // Clear the selected batch when switching to create mode
        selectedBatch.value = null;
    }
}

async function handleSavePlotSettings() {
    if (batchSelectionMode.value === 'Select existing' && !selectedBatch.value) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a batch.', life: 3000 });
        return false;
    }
    if (batchSelectionMode.value === 'Create new' && !newBatchLabel.value) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please enter a batch label.', life: 3000 });
        return false;
    }
    if (!selectedCommodity.value) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: 'You have to select a commodity.', life: 3000 });
        return;
    }
    if (selectedCommodity.value === 'Wood') {
        if (!selectedTreeSpecies.value) {
            toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: 'You have to select a tree species.', life: 3000 });
            return;
        }
    }

    for (const plot of dataImportStore.plotsWithUserDetails) {
        plot.plotId = plot.properties[dataImportStore.selectedPlotIdColumn];

        if (dataImportStore.selectedAreaColumn) {
            plot.area = plot.properties[dataImportStore.selectedAreaColumn];
        }
        if (dataImportStore.selectedProducerNameColumn) {
            plot.producerName = plot.properties[dataImportStore.selectedProducerNameColumn];
        }
    }

    await importPlots();
}

function initializeWorker() {
    const worker = new Worker(new URL('./importWorker.js', import.meta.url), { type: 'module' })

    worker.onerror = (error) => {
        Sentry.captureException(error);
        console.log(error);
        workerInstance.value = null;  // Reset the instance on error
    };
    workerInstance.value = Comlink.wrap(worker);
}

async function getWorker() {
    if (!workerInstance.value) {
        initializeWorker();
    }

    return workerInstance;
}

async function preparePlotsForUpload() {
    const comlinkWorker = await getWorker();
    const plotsWithUserDetails = toRaw(dataImportStore.plotsWithUserDetails);
    const areaColumn = toRaw(dataImportStore.selectedAreaColumn);
    const serializedData = JSON.stringify({ plotsWithUserDetails, areaColumn });
    const preparedData = await comlinkWorker.value.preparePlots(serializedData);

    preparedData.batch = {};
    if (batchSelectionMode.value === 'Create new') {
        preparedData.batch.isNewBatch = true;
        preparedData.batch.newBatchLabel = newBatchLabel.value;
    } else {
        preparedData.batch.isNewBatch = false;
        preparedData.batch.batchObjectId = selectedBatch.value._id;
    }

    preparedData.commodity = selectedCommodity.value;

    if (selectedCommodity.value === 'Wood') {
        preparedData.treeSpecies = selectedTreeSpecies.value;
    }
    if (selectedSupplier.value && selectedSupplier.value._id) {
        preparedData.supplierObjectId = selectedSupplier.value._id;
    }

    return preparedData
}

// Frontend polling
async function pollJobStatus(jobId) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const checkStatus = async () => {
            try {
                const response = await getPollStatus(jobId);
                console.log("Poll response:", response);

                if (response.jobStatus === "complete") {
                    if (response.result === "success") {
                        resolve(response);
                    } else {
                        reject(new Error(response.result?.error || "Task failed"));
                    }
                } else if (response.jobStatus === "error") {
                    reject(new Error("Task failed"));
                } else if (["queued", "in_progress", "deferred"].includes(response.jobStatus)) {
                    console.log("Job still in progress...");
                    setTimeout(checkStatus, pollInterval);
                }
            } catch (error) {
                console.error("Error while polling job status:", error);
                reject(error);
            }
        };

        checkStatus();
    });
}

async function importPlots() {
    isImportPlotsLoading.value = true;
    const dataToSend = await preparePlotsForUpload();
    const formData = new FormData();

    formData.append("data", JSON.stringify(dataToSend));
    formData.append("file", dataImportStore.file);

    let job;
    try {
        const response = await postCreatePlotsWithImport(formData);
        console.log(response)
        job = await pollJobStatus(response.jobId);
        dataImportStore.tableFiles = await getAllFiles();

        if (job.result === "success") {
            dataImportStore.closeDataImportDialog()
            toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: "Plots successfully imported.", life: 3000 });
            refreshData();
        } else {
            toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to import plots.", life: 3000 });
        }
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        dataImportStore.showDataImportFailureDialog();
    } finally {
        isImportPlotsLoading.value = false;
    }
}

onMounted(async () => {
    try {
        await supplierStore.fetchAllSuppliers();
        prettyBatches.value = batchStore.allBatches.map(batch => ({ _id: batch._id, label: batch.label }));
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to fetch suppliers.", life: 3000 });
    }
})
</script>