<template>
    <Dialog v-model:visible="plotsStore.isCreateDialogShown" header="Create a new plot" modal :closeOnEscape="false"
        :closable="false" :style="{ width: '30rem' }">
        <div class="field">
            <label class="font-bold">Plot ID</label>
            <InputText v-model="formState.plotId" class="w-full" />
            <small class="text-gray-500">
                If left empty, a Plot ID will be automatically generated.
            </small>
        </div>
        <div class="field">
            <label class="font-bold">Batch <span>*</span></label>
            <SelectButton v-model="batchSelectionMode" :options="batchModeOptions" class="mb-2 w-full"
                :allowEmpty="false" @change="handleBatchModeChange" />
            <div v-if="batchSelectionMode === 'Select existing'" class="mt-2">
                <Dropdown v-model="formState.batch" :options="batchStore.allBatches" optionLabel="label" class="w-full">
                </Dropdown>
            </div>
            <div v-else class="mt-2">
                <InputText v-model="formState.newBatchLabel" placeholder="Enter new batch label" class="w-full" />
            </div>
        </div>
        <div class="field">
            <label class="font-bold">Commodity <span>*</span></label>
            <Dropdown v-model="formState.commodity" :options="COMMODITIES" class="w-full">
            </Dropdown>
        </div>
        <div class="field" v-if="formState.commodity === 'Wood'">
            <label class="font-bold">Tree species <span>*</span></label>
            <Dropdown :model-value="getSelectedTreeSpecies(formState.treeSpecies)"
                @update:model-value="updateTreeSpecies(formState.treeSpecies, $event)" :options="TREES"
                optionLabel="tree" class=" w-full" placeholder="Select Tree Species" showClear>
                <template #value="slotProps">
                    <span v-if="slotProps.value && slotProps.value.tree">{{
                        slotProps.value?.tree }} ({{
                            slotProps.value?.scientific }})</span>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})
                        </div>
                    </div>
                </template>
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Supplier</label>
            <Dropdown v-model="formState.supplier" :options="supplierStore.allSuppliers" optionLabel="name"
                optionValue="_id" class="w-full">
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Producer name</label>
            <InputText v-model="formState.producerName" class="w-full" />
        </div>
        <div class="field">
            <label class="font-bold">Area (ha)</label>
            <InputText v-model="formState.area" class="w-full"
                :disabled="plotsStore.drawnFeature.geometry?.type !== 'Point'" />
            <small class="text-gray-500">
                Point-based plots require an area size (max 4ha). If unspecified, 4ha is used by default.
            </small>
        </div>
        <div class="field">
            <label class="font-bold">Additional information</label>
            <Textarea v-model="formState.additionalInfo" rows="5" cols="30" class="w-full" autoResize />
        </div>
        <template #footer>
            <Button label="Cancel" class="mr-3" severity="secondary" @click="closeDialog"></Button>
            <Button label="Create plot" @click="createPlot" :loading="isPlotCreating"
                class="light-green-nadar"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, inject, watch } from "vue";
import { usePlotsStore } from "../../stores/plots";
import { useToast } from "primevue/usetoast";
import { COMMODITIES, TREES } from '../../utils/constants';
import { useBatchStore } from "../../stores/batch";
import { useDataFormatUtils } from "../../composables/useDataFormatUtils";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import * as Sentry from "@sentry/vue";
import { usePlotsUtils } from "./composables/usePlotsUtils";
import { useSupplierStore } from "../../stores/supplier";

const { isNumber } = useDataFormatUtils();
const { refreshData } = usePlotsUtils();
const { postCreatePlot } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const batchStore = useBatchStore();
const plotsStore = usePlotsStore();
const toast = useToast();
const draw = inject("draw");
const isPlotCreating = ref(false);
const batchSelectionMode = ref('Select existing'); // Default to select mode
const batchModeOptions = ref(['Select existing', 'Create new']);
const formState = ref({
    plotId: null,
    batch: null,
    newBatchLabel: null,
    commodity: null,
    treeSpecies: null,
    supplier: null,
    producerName: null,
    area: null,
    additionalInfo: null
});


function resetFormState() {
    formState.value = {
        plotId: null,
        batch: null,
        newBatchLabel: null,
        commodity: null,
        treeSpecies: null,
        supplier: null,
        producerName: null,
        area: null,
        additionalInfo: null
    };
    batchSelectionMode.value = 'Select existing';
}

function closeDialog() {
    draw.deleteAll();
    plotsStore.isCreateDialogShown = false;
}

function handleBatchModeChange(event) {
    const newMode = event.value;

    if (newMode === 'Select existing') {
        // Clear the new batch label when switching to select mode
        formState.value.newBatchLabel = null;
    } else {
        // Clear the selected batch when switching to create mode
        formState.value.batch = null;
    }
}

async function createPlot() {
    const isVerified = verifyPlotDetails();
    if (isVerified) {
        try {
            const body = {
                plotId: formState.value.plotId,
                creationType: "drawn",
                geometry: plotsStore.drawnFeature.geometry,
                commodity: formState.value.commodity,
                producerName: formState.value.producerName,
                additionalInfo: formState.value.additionalInfo,
                batch: {}
            };

            if (formState.value.supplier) {
                body.supplierObjectId = formState.value.supplier;
            }

            if (batchSelectionMode.value === 'Create new') {
                body.batch.isNewBatch = true;
                body.batch.newBatchLabel = formState.value.newBatchLabel;
            } else {
                body.batch.isNewBatch = false;
                body.batch.batchObjectId = formState.value.batch._id;
            }

            if (formState.value.area) {
                body.area = parseFloat(formState.value.area);
            }

            if (formState.value.commodity === "Wood" && formState.value.treeSpecies) {
                body.treeSpecies = formState.value.treeSpecies;
            }

            isPlotCreating.value = true;
            console.log(body)
            await postCreatePlot(body);
            toast.add({ group: 'headless', severity: 'success', summary: 'Success', detail: 'Plot successfully created.', life: 3000 });
            plotsStore.isCreateDialogShown = false;
            refreshData();
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.add({ group: 'headless', severity: 'error', summary: 'Error', detail: 'Failed to create plot.', life: 3000 });
        } finally {
            isPlotCreating.value = false;
        }
    }
}

function getSelectedTreeSpecies(selectedTreeSpecies) {
    const customerCommodity = TREES.find(tree => tree.tree === selectedTreeSpecies?.tree);
    return customerCommodity;
}

function updateTreeSpecies(rowData, newValue) {
    formState.value.treeSpecies = newValue ? newValue : null;
}

function verifyPlotDetails() {
    if (batchSelectionMode.value === 'Select existing' && !formState.value.batch) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a batch.', life: 3000 });
        return false;
    }
    if (batchSelectionMode.value === 'Create new' && !formState.value.newBatchLabel) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please enter a batch label.', life: 3000 });
        return false;
    }
    if (!formState.value.commodity) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a commodity.', life: 3000 });
        return false;
    }
    if (formState.value.commodity === "Wood" && !formState.value.treeSpecies?.tree) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a tree species.', life: 3000 });
        return false;
    }
    if (plotsStore.drawnFeature.geometry.type === "Point") {
        if (formState.value.area) {
            if (!isNumber(formState.value.area)) {
                toast.add({ group: 'headless', severity: 'info', summary: 'Numeric Value', detail: "The area must be a numeric value.", life: 3000 });
                return false;
            }
            // Check if the area exceeds the limit
            if (formState.value.area > 4) {
                toast.add({ group: 'headless', severity: 'info', summary: 'Area Exceeded', detail: "The area must not exceed 4 ha.", life: 3000 });
                return false;
            }
        }
    }

    return true;
}

// Watch for dialog visibility changes
watch(() => plotsStore.isCreateDialogShown, (isVisible) => {
    if (isVisible) {
        // Dialog is opening - populate dropdown options
    } else {
        // Dialog is closing - reset form
        resetFormState();
    }
});
</script>
