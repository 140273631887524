<template>
    <Dialog v-model:visible="plotsStore.isEditDialogShown" header="Edit plot" modal :closeOnEscape="false"
        :closable="false" :style="{ width: '30rem' }">
        <div class="field">
            <label class="font-bold">Plot ID</label>
            <InputText v-model="plotsStore.plotToEdit.plotId" class="w-full" />
        </div>
        <div class="field">
            <label class="font-bold">Batch <span>*</span></label>
            <Dropdown v-model="selectedBatchId" :options="batchStore.allBatches" optionLabel="label" optionValue="_id"
                class="w-full">
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Commodity <span>*</span></label>
            <Dropdown v-model="plotsStore.plotToEdit.commodity" :options="COMMODITIES" class="w-full">
            </Dropdown>
        </div>
        <div class="field" v-if="plotsStore.plotToEdit.commodity === 'Wood'">
            <label class="font-bold">Tree species <span>*</span></label>
            <Dropdown :model-value="getSelectedTreeSpecies(plotsStore.plotToEdit.treeSpecies)"
                @update:model-value="updateTreeSpecies(plotsStore.plotToEdit.treeSpecies, $event)" :options="TREES"
                optionLabel="tree" class=" w-full" placeholder="Select Tree Species" showClear>
                <template #value="slotProps">
                    <span v-if="slotProps.value && slotProps.value.tree">{{
                        slotProps.value?.tree }} ({{
                            slotProps.value?.scientific }})</span>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})
                        </div>
                    </div>
                </template>
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Supplier</label>
            <Dropdown v-model="selectedSupplierId" :options="supplierStore.allSuppliers" optionLabel="name"
                optionValue="_id" class="w-full">
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Producer name</label>
            <InputText v-model="plotsStore.plotToEdit.producerName" class="w-full" />
        </div>
        <div class="field">
            <label class="font-bold">Additional information</label>
            <Textarea v-model="plotsStore.plotToEdit.additionalInfo" rows="5" cols="30" class="w-full" autoResize />
        </div>
        <template #footer>
            <Button label="Cancel" class="mr-3" severity="secondary" @click="plotsStore.hideEditDialog"></Button>
            <Button label="Update plot" @click="updatePlot" :loading="isPlotUpdating"
                class="light-green-nadar"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { usePlotsStore } from "../../stores/plots";
import { useToast } from "primevue/usetoast";
import { COMMODITIES, TREES } from '../../utils/constants';
import { useBatchStore } from "../../stores/batch";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import * as Sentry from "@sentry/vue";
import { usePlotsUtils } from "./composables/usePlotsUtils";
import { useSupplierStore } from "../../stores/supplier";


const { refreshData } = usePlotsUtils();
const { patchPlot } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const batchStore = useBatchStore();
const plotsStore = usePlotsStore();
const toast = useToast();
const isPlotUpdating = ref(false);

const selectedBatchId = computed({
    get: () => plotsStore.plotToEdit.batch?._id || null,
    set: (value) => {
        // Find the full batch object to get the label
        const batchLabel = batchStore.allBatches.find(b => b._id === value)?.label || '';
        plotsStore.plotToEdit.batch = { _id: value, label: batchLabel };
    }
});

const selectedSupplierId = computed({
    get: () => plotsStore.plotToEdit.supplier?._id || null,
    set: (value) => {
        plotsStore.plotToEdit.supplier = { _id: value };
    }
});


async function updatePlot() {
    const isVerified = verifyPlotDetails();
    if (isVerified) {
        try {
            const body = {
                plotId: plotsStore.plotToEdit.plotId,
                creationType: "drawn",
                commodity: plotsStore.plotToEdit.commodity,
                producerName: plotsStore.plotToEdit.producerName,
                additionalInfo: plotsStore.plotToEdit.additionalInfo,
            };

            if (selectedSupplierId.value) {
                body.supplier = selectedSupplierId.value;
            }

            if (selectedBatchId.value) {
                body.batch = selectedBatchId.value;
            }

            if (plotsStore.plotToEdit.commodity === "Wood" && plotsStore.plotToEdit.treeSpecies) {
                body.treeSpecies = plotsStore.plotToEdit.treeSpecies;
            }

            isPlotUpdating.value = true;
            await patchPlot(plotsStore.plotToEdit._id, body);
            toast.add({ group: 'headless', severity: 'success', summary: 'Success', detail: 'Plot successfully created.', life: 3000 });
            plotsStore.hideEditDialog();
            refreshData();
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.add({ group: 'headless', severity: 'error', summary: 'Error', detail: 'Failed to create plot.', life: 3000 });
        } finally {
            isPlotUpdating.value = false;
        }
    }
}

function getSelectedTreeSpecies(selectedTreeSpecies) {
    const customerCommodity = TREES.find(tree => tree.tree === selectedTreeSpecies?.tree);
    return customerCommodity;
}

function updateTreeSpecies(rowData, newValue) {
    plotsStore.plotToEdit.treeSpecies = newValue ? newValue : null;
}

function verifyPlotDetails() {
    if (!plotsStore.plotToEdit.plotId) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please enter a plot ID.', life: 3000 });
        return false;
    }
    if (!selectedBatchId) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a batch.', life: 3000 });
        return false;
    }
    if (!plotsStore.plotToEdit.commodity) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a commodity.', life: 3000 });
        return false;
    }
    if (plotsStore.plotToEdit.commodity === "Wood" && !plotsStore.plotToEdit.treeSpecies?.tree) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please select a tree species.', life: 3000 });
        return false;
    }

    return true;
}
</script>
