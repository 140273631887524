<template>
    <div class="mapbox-info-button" @click="toggleInfoBox">
        <i class="pi pi-info-circle"></i>
    </div>
    <div class="mapbox-info-box" v-if="isInfoBoxVisible">
        <span>
            <a href="https://www.mapbox.com/about/maps" target=”_blank”>© Mapbox </a>
            <a href="https://www.openstreetmap.org/about/" target=”_blank”>© OpenStreetMap </a>
            <a href="https://www.maxar.com/" target=”_blank”>© Maxar</a>
        </span>
    </div>
</template>

<script setup>
import { ref } from "vue";


const isInfoBoxVisible = ref(false);


function toggleInfoBox() {
    isInfoBoxVisible.value = !isInfoBoxVisible.value;
}
</script>

<style scoped>
.mapbox-info-button {
    position: absolute;
    bottom: 10px;
    /* Adjust based on your layout */
    right: 10px;
    /* Adjust based on your layout */
    z-index: 1000;
    /* Ensure it's above the map */
    background-color: white;
    /* Background color of the circle */
    width: 25px;
    /* Diameter of the circle */
    height: 25px;
    /* Diameter of the circle */
    display: flex;
    align-items: center;
    /* Center the icon vertically */
    justify-content: center;
    /* Center the icon horizontally */
    border-radius: 50%;
    /* Makes the div a circle */
    cursor: pointer;
    /* Changes cursor to pointer on hover */
}

.mapbox-info-box {
    position: absolute;
    bottom: 10px;
    /* Adjust based on your layout */
    right: 11px;
    align-items: center;
    padding: 3px 12px;
    background-color: white;
    border-radius: 20px;
    /* Pill shape */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    z-index: 999;
}

.mapbox-info-box a {
    text-decoration: none;
    /* Removes underline */
    color: #000;
    /* Change color to black; adjust as needed */
}

.mapbox-info-box a:hover {
    text-decoration: underline;
    /* Optional: underline on hover for better UX */
    color: #000000;
    /* Optional: change color on hover; adjust as needed */
}

.mapbox-info-box a:last-child {
    margin-right: 15px;
    /* Adds space after the last link */
}
</style>