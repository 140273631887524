<template>
    <Dialog v-model:visible="batchStore.isDeleteDialogShown" modal header="Delete batch?" :closable="false"
        :style="{ width: '30rem' }">
        <span>Delete <span class="font-medium">{{ batchStore.batchToDelete.label }}</span>? This action cannot be
            undone.</span>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-3" @click="batchStore.hideDeleteDialog"></Button>
            <Button label="Delete" severity="danger" @click="handleDeletePlots" :loading="isDeleteLoading"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useBatchStore } from '../../stores/batch';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import * as Sentry from "@sentry/vue";


const { deleteBatch } = useAnalyticsAPI();
const toast = useToast();
const batchStore = useBatchStore();
const isDeleteLoading = ref(false);


async function handleDeletePlots() {
    try {
        isDeleteLoading.value = true;
        await deleteBatch(batchStore.batchToDelete._id);
        batchStore.hideDeleteDialog();
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Batch successfully deleted.', life: 3000 });
        batchStore.fetchAllBatches();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to delete batch.", life: 3000 });
    } finally {
        isDeleteLoading.value = false;
    }
}
</script>