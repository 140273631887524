<template>
    <Dialog v-model:visible="supplierStore.isCreateDialogShown" modal header="Create a new supplier"
        :closeOnEscape="false" :closable="false" :style="{ width: '50rem' }">
        <div class="grid">
            <div class="col-6">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Name *</label>
                    <InputText v-model="formState.name" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Commodities</label>
                    <MultiSelect v-model="formState.commodities" :options="COMMODITIES" :maxSelectedLabels="5" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Email</label>
                    <InputText v-model="formState.email" />
                </div>
            </div>
            <div class="col-6">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Country *</label>
                    <Dropdown v-model="formState.country" :options="COUNTRIES" filter />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Contact name</label>
                    <InputText v-model="formState.contact" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Phone</label>
                    <InputText v-model="formState.phone" />
                </div>
            </div>
            <div class="col-12">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Address</label>
                    <InputText v-model="formState.address" />
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-2" @click="supplierStore.hideCreateDialog" />
            <Button label="Create supplier" class="light-green-nadar" @click="createSupplier" :loading="isCreating" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { COMMODITIES, COUNTRIES } from '../../utils/constants';
import { useSupplierStore } from '../../stores/supplier';
import * as Sentry from "@sentry/vue";


const { postSupplier } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const toast = useToast();
const isCreating = ref(false);
const formState = ref({
    name: null,
    country: null,
    commodities: null,
    email: null,
    contact: null,
    phone: null,
    address: null
});


function resetFormState() {
    formState.value = {
        name: null,
        country: null,
        commodities: null,
        email: null,
        contact: null,
        phone: null,
        address: null
    };
}

async function createSupplier() {
    if (!formState.value.name || !formState.value.country) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: "Please fill out all required fields.", life: 3000 });
        return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formState.value.email && !emailPattern.test(formState.value.email.trim())) {
        toast.add({ group: "headless", severity: 'error', summary: 'Invalid Email', detail: "Please enter a valid email address.", life: 3000 });
        return;
    }

    try {
        isCreating.value = true;
        const supplierData = {
            name: formState.value.name?.trim() || undefined,
            phone: formState.value.phone?.trim() || undefined,
            contact: formState.value.contact?.trim() || undefined,
            email: formState.value.email?.trim().toLowerCase() || undefined,
            country: formState.value.country || undefined,
            address: formState.value.address?.trim() || undefined
        };

        if (formState.value.commodities && formState.value.commodities.length > 0) {
            supplierData.commodities = formState.value.commodities.map(commodity => ({ "commodity": commodity }));
        }

        await postSupplier(supplierData);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Supplier successfully created.', life: 3000 });
        supplierStore.hideCreateDialog();
        await supplierStore.fetchAllSuppliers();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to create supplier.', life: 3000 });
    } finally {
        isCreating.value = false;
    }
}

watch(() => supplierStore.isCreateDialogShown, (isVisible) => {
    if (!isVisible) {
        resetFormState();
    }
});
</script>