<template>
    <Card class="mt-3">
        <template #content>
            <DataTable :value="supplierStore.allSuppliers" dataKey="_id" size="small" scrollHeight="calc(100vh - 100px)"
                scrollable>
                <template #empty>
                    <div v-if="!supplierStore.isTableLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <img :src="placeholder" class="mb-2" width="150" />
                        <span>No suppliers available for display.</span>
                        <small>Please create a new supplier.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
                <Column field="name" header="Name"></Column>
                <Column field="country" header="Country"></Column>
                <Column field="commodities" header="Commodities">
                    <template #body="{ data }">
                        <div style="display: flex; align-items: center; flex-wrap: wrap;">
                            <div v-for="item in data.commodities" :key="item.commodity"
                                class="commodity-pill mr-2 mt-1">
                                {{ item.commodity }}
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="" header="Actions">
                    <template #body="{ data }">
                        <Button icon="pi pi-pencil" class="mr-2" outlined size="small"
                            @click="supplierStore.showEditDialog(data)" />
                        <Button icon="pi pi-trash" outlined size="small"
                            @click="supplierStore.showDeleteDialog(data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>

    <EditSupplierDialog />
    <DeleteSupplierDialog />
</template>


<script setup>
import { onMounted } from "vue";
import EditSupplierDialog from './EditSupplierDialog.vue';
import DeleteSupplierDialog from "./DeleteSupplierDialog.vue";
import placeholder from "../../assets/placeholder_suppliers.svg"
import { useSupplierStore } from "../../stores/supplier";


const supplierStore = useSupplierStore();


onMounted(() => {
    supplierStore.fetchAllSuppliers();
})
</script>

<style scoped>
.commodity-pill {
    background-color: #A1E888;
    border-radius: 20px;
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    margin: 2px;
}
</style>