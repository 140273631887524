<template>
    <Card>
        <template #content>
            <DataTable :value="batchStore.allBatches" v-model:filters="filters" v-model:selection="selectedBatch"
                selectionMode="single" dataKey="_id" size="small" scrollable scrollHeight="calc(100vh - 280px)"
                @rowSelect="selectBatch" removableSort
                :globalFilterFields="['label', 'internalReferenceNumber', 'plotCount', 'areaCount', 'countries', 'updatedAt']">
                <template #header>
                    <div class="flex justify-content-end">
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Search" />
                        </IconField>
                    </div>
                </template>
                <Column field="label" header="Batch label" sortable></Column>
                <Column field="internalReferenceNumber" header="Internal reference number" sortable></Column>
                <Column field="plotCount" header="No. of plots" sortable></Column>
                <Column field="areaCount" header="Total area (ha)" sortable></Column>
                <Column field="countries" header="Countries" sortable>
                    <template #body="{ data }">
                        {{ data.countries.join(', ') }}
                    </template>
                </Column>
                <Column field="updatedAt" header="Last updated" sortable>
                    <template #body="{ data }">
                        {{ formatDate(data.updatedAt) }}
                    </template>
                </Column>
                <Column header="Actions">
                    <template #body="{ data }">
                        <Button icon="pi pi-copy" outlined size="small" class="mr-2"
                            @click="batchStore.showDuplicateDialog(data)" />
                        <Button icon="pi pi-pencil" outlined size="small" class="mr-2"
                            @click="batchStore.showEditDialog(data)" />
                        <Button icon="pi pi-trash" outlined size="small" @click="batchStore.showDeleteDialog(data)" />
                    </template>
                </Column>
                <template #empty>
                    <div v-if="!batchStore.isLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <img :src="placeholder" class="mb-2" width="150" />
                        <span class="font-medium mb-2">No batches found</span>
                        <small>You can create a batch when you import or draw a plot.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
            </DataTable>
        </template>
    </Card>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import placeholder from "../../assets/placeholder_suppliers.svg"
import { useBatchStore } from "../../stores/batch"
import { useDataFormatUtils } from "../../composables/useDataFormatUtils";
import { FilterMatchMode } from 'primevue/api';


const { formatDate } = useDataFormatUtils();
const batchStore = useBatchStore();
const router = useRouter();
const selectedBatch = ref();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});


function selectBatch(event) {
    selectedBatch.value = event.data;
    const batchObjectId = event.data._id;

    for (const batch of batchStore.allBatches) {
        if (batch._id === batchObjectId) {
            router.push({ name: "batch", params: { batchObjectId } });
            break;
        }
    }
}
</script>