<template>
    <div class="flex border-round draw-polygon-icon" v-tooltip.left="'Draw Polygon'" @click="toggleDrawingPolygonMode">
        <div class="flex align-items-center justify-content-center w-full">
            <img :src="polygonIcon" width="20" />
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import polygonIcon from "../../assets/draw-polygon.png";


const draw = inject('draw');


function toggleDrawingPolygonMode() {
    draw.changeMode('draw_polygon');
}
</script>

<style scoped>
.draw-polygon-icon {
    position: absolute;
    top: 110px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
    width: 29px;
    height: 29px;
    cursor: pointer;
}

.draw-polygon-icon:hover {
    background-color: #e6e6e6;
    border-style: solid;
    border-color: rgb(219, 219, 219);
}
</style>