<template>
    <div class="suppliers-container">
        <div class="flex justify-content-between align-items-center">
            <span class="font-medium text-2xl">Suppliers</span>
            <div>
                <Button label="Create supplier" class="mr-2" @click="supplierStore.showCreateDialog" />
                <Button icon="pi pi-sync" @click="supplierStore.fetchAllSuppliers"
                    :loading="supplierStore.isTableLoading" />
            </div>
        </div>
        <SuppliersTable />
    </div>

    <CreateSupplierDialog />
</template>

<script setup>
import SuppliersTable from "../components/suppliers/SuppliersTable.vue";
import CreateSupplierDialog from "../components/suppliers/CreateSupplierDialog.vue";
import { useSupplierStore } from "../stores/supplier";


const supplierStore = useSupplierStore();
</script>

<style scoped>
.suppliers-container {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}
</style>