<template>
    <Dialog v-model:visible="batchStore.isEditDialogShown" header="Edit batch" modal :closable="false"
        :style="{ width: '30rem' }">
        <div class="field">
            <label class="font-medium">Batch label *</label>
            <InputText v-model="formState.label" class="w-full" />
        </div>
        <div class="field">
            <label class="font-medium">Internal reference number</label>
            <InputText v-model="formState.internalReferenceNumber" class="w-full" />
        </div>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-3" @click="batchStore.hideEditDialog"></Button>
            <Button label="Update" class="light-green-nadar" @click="updateBatch" :loading="isUpdating"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useBatchStore } from '../../stores/batch';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';


const { patchBatch } = useAnalyticsAPI();
const toast = useToast();
const batchStore = useBatchStore();
const isUpdating = ref(false);
const formState = ref({
    label: null,
    internalReferenceNumber: null
})


function resetFormState() {
    formState.value = {
        label: null,
        internalReferenceNumber: null
    };
}

async function updateBatch() {
    const trimmedLabel = formState.value.label?.trim();
    const trimmedRefNumber = formState.value.internalReferenceNumber?.trim();

    if (!trimmedLabel) {
        toast.add({ group: 'headless', severity: 'info', summary: 'Missing fields', detail: 'Please enter a valid batch label.', life: 3000 });
        return false;
    }

    // Create body with all form fields, using trimmed values
    const body = {
        label: trimmedLabel,
        internalReferenceNumber: trimmedRefNumber
    }

    try {
        isUpdating.value = true;
        await patchBatch(batchStore.batchToEdit._id, body);
        toast.add({ group: 'headless', severity: 'success', summary: 'Success', detail: 'Batch successfully updated.', life: 3000 });
        batchStore.fetchAllBatches();
        batchStore.hideEditDialog();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: 'headless', severity: 'error', summary: 'Error', detail: 'Failed to update batch.', life: 3000 });
    } finally {
        isUpdating.value = false;
    }
}

watch(() => batchStore.isEditDialogShown, (isVisible) => {
    if (isVisible) {
        formState.value.label = batchStore.batchToEdit.label;
        formState.value.internalReferenceNumber = batchStore.batchToEdit.internalReferenceNumber || null;
    } else {
        resetFormState();
    }
});
</script>