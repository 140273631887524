import { defineStore } from 'pinia'
import { useAnalyticsAPI } from '../composables/useAnalyticsAPI';

export const useBatchStore = defineStore('batch', {
    state: () => ({
        allBatches: [],
        activeBatch: null,
        isLoading: true,
        isEditDialogShown: false,
        isCreateDialogShown: false,
        isDuplicateDialogShown: false,
        isDeleteDialogShown: false,
        batchToEdit: null,
        batchToDuplicate: null,
        batchToDelete: null,
    }),
    actions: {
        async fetchAllBatches() {
            const { getAllBatches } = useAnalyticsAPI();
            this.isLoading = true;
            this.allBatches = [];

            try {
                this.allBatches = await getAllBatches();
            } catch (error) {
                console.log(error)
                throw error
            } finally {
                this.isLoading = false;
            }
        },

        showEditDialog(batch) {
            this.batchToEdit = batch;
            this.isEditDialogShown = true;
        },

        hideEditDialog() {
            this.batchToEdit = null;
            this.isEditDialogShown = false;
        },

        showDeleteDialog(batch) {
            this.batchToDelete = batch;
            this.isDeleteDialogShown = true;
        },

        hideDeleteDialog() {
            this.batchToDelete = null;
            this.isDeleteDialogShown = false;
        },

        showDuplicateDialog(batch) {
            this.batchToDuplicate = batch;
            this.isDuplicateDialogShown = true;
        },

        hideDuplicateDialog() {
            this.batchToDuplicate = null;
            this.isDuplicateDialogShown = false;
        },
    }
})