<template>
    <div id="plotsMap" class="border-round">
        <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
        <MapboxAttributions />
        <div>
            <DrawPolygon />
            <PlaceMarker />
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, inject } from "vue";
import { useToast } from 'primevue/usetoast';
import MapboxAttributions from "../common/MapboxAttributions.vue";
import PlaceMarker from "./PlaceMarker.vue";
import nadarLogo from "../../assets/nadarLogoWhite.png"
import { usePlotsStore } from "../../stores/plots.js";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DrawPolygon from "./DrawPolygon.vue";


const VITE_TILES_SERVICE_EXPRESS_URL = import.meta.env.VITE_TILES_SERVICE_EXPRESS_URL;
const VITE_MAPBOX3_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX3_ACCESS_TOKEN;
mapboxgl.accessToken = VITE_MAPBOX3_ACCESS_TOKEN;

const map = inject("map");
const draw = inject("draw");
const plotsStore = usePlotsStore();
const toast = useToast();

function showPlotPopup(properties, lngLat) {
    const plotId = properties.plotId || 'N/A';

    const popupContent = document.createElement('div');
    popupContent.innerHTML = `
        <div class="marker-info">
            <table class="metadata">
                <tbody>
                    <tr><td><strong>Plot ID</strong></td><td>${plotId}</td></tr>
                </tbody>
            </table>
        </div>
    `;

    // Create and show the Mapbox popup
    const popup = new mapboxgl.Popup({ closeButton: false, offset: [0, -38] })
        .setLngLat(lngLat)
        .setDOMContent(popupContent)
        .addTo(map.value);
}

function handleEscapeKey(e) {
    if (e.key === 'Escape') {
        draw.deleteAll();
        draw.changeMode('simple_select');
    }
}

onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey);
    map.value = new mapboxgl.Map({
        container: "plotsMap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_TILES_SERVICE_EXPRESS_URL)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    map.value.addControl(new mapboxgl.NavigationControl());
    map.value.addControl(draw);

    map.value.on("click", "polygons-layer-fill", (e) => {
        const features = map.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        const lngLat = e.lngLat;

        showPlotPopup(properties, lngLat);
    });

    map.value.on("click", "points-layer", (e) => {
        const features = map.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        const lngLat = e.lngLat;

        showPlotPopup(properties, lngLat);
    });

    map.value.on('load', () => {
        map.value.addSource('all-plots-source', {
            type: 'vector',
            tiles: [`${VITE_TILES_SERVICE_EXPRESS_URL}/plots/{z}/{x}/{y}.pbf`],
            maxzoom: 16,
            minzoom: 0
        });

        // Clusters (for zoom < 10)
        map.value.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'all-plots-source',
            'source-layer': 'all-plots',
            maxzoom: 12,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#FFFFFF',    // 0 to 100
                    100, '#FFFFFF', // 100 to 750
                    750, '#FFFFFF'  // 750+
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,    // 0 to 100
                    100, 20,    // 100 to 750
                    750, 20     // 750+
                ]
            }
        });

        map.value.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'all-plots-source',
            'source-layer': 'all-plots',
            maxzoom: 12,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}', // Use the abbreviated version here
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            }
        });

        map.value.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'all-plots-source',
            'source-layer': 'all-plots',
            maxzoom: 12,
            filter: ['!', ['has', 'point_count']],  // Select only individual points (not clusters)
            paint: {
                'circle-radius': 6,
                'circle-color': '#FF69B4',  // Bright pink for visibility
                'circle-stroke-width': 2,
                'circle-stroke-color': '#ffffff'  // White stroke to make it pop
            }
        });

        // Full geometry layers (for zoom >= 10)
        map.value.addLayer({
            'id': 'polygons-layer-fill',
            'type': 'fill',
            'source': 'all-plots-source',
            'source-layer': 'all-plots',
            'filter': ['==', '$type', 'Polygon'],
            'minzoom': 12,
            'paint': {
                'fill-color': 'rgba(0, 0, 0, 0)',
                'fill-opacity': 0.5
            }
        });

        map.value.addLayer({
            'id': 'polygons-layer-line',
            'type': 'line',
            'source': 'all-plots-source',
            'source-layer': 'all-plots',
            'filter': ['==', '$type', 'Polygon'],
            'minzoom': 12,
            'paint': {
                'line-color': "#FF69B4",
                'line-width': 3
            }
        });

        map.value.addLayer({
            'id': 'points-layer',
            'type': 'circle',
            'source': 'all-plots-source',
            'source-layer': 'all-plots',
            'filter': ['==', '$type', 'Point'],
            'minzoom': 12,
            'paint': {
                'circle-radius': 4,
                'circle-color': '#FF69B4'
            }
        });

        // Click handler for clusters
        map.value.on('click', 'clusters', (e) => {
            const features = map.value.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });

            map.value.flyTo({
                center: e.lngLat,
                zoom: Math.min(map.value.getZoom() + 2, 12)
            });
        });

        // Cursor interactions
        map.value.on('mouseenter', 'clusters', () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        map.value.on('mouseleave', 'clusters', () => {
            map.value.getCanvas().style.cursor = '';
        });

        map.value.on('mouseenter', 'polygons-layer-fill', () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        map.value.on('mouseenter', 'points-layer', () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        map.value.on('mouseleave', 'polygons-layer-fill', () => {
            map.value.getCanvas().style.cursor = '';
        });

        map.value.on('mouseleave', 'points-layer', () => {
            map.value.getCanvas().style.cursor = '';
        });
    });

    map.value.on("draw.create", async (event) => {
        plotsStore.drawnFeature = event.features[0];
        plotsStore.isCreateDialogShown = true;
    });

    map.value.on("style.load", () => {
        map.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        });
    });
});

onUnmounted(() => {
    map.value.remove();
    document.removeEventListener('keydown', handleEscapeKey);
});
</script>

<style scoped>
#plotsMap {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

:deep(.mapboxgl-ctrl-logo) {
    position: absolute;
    bottom: 0px;
    left: 80px;
    /* Adjust the value to create space for your logo */
}

:deep(.marker-info) {
    font-family: Arial, sans-serif;
    font-size: 12px;
}

:deep(.metadata) {
    border-collapse: collapse;
    width: 100%;
}

:deep(.metadata td) {
    padding: 3px;
    border: 1px solid #ddd;
}

:deep(.metadata tr:nth-child(even)) {
    background-color: #f9f9f9;
}

:deep(.metadata tr:hover) {
    background-color: #f1f1f1;
}
</style>