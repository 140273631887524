<template>
    <div class="batches-container">
        <div class="flex justify-content-between align-items-center">
            <span class="font-medium text-2xl">Batches</span>
            <div>
                <Button icon="pi pi-sync" @click="batchStore.fetchAllBatches" :loading="batchStore.isLoading" />
            </div>
        </div>
        <div class="mt-3">
            <BatchesDashboard />
            <BatchesTable />
        </div>
    </div>

    <DuplicateBatchDialog />
    <DeleteBatchDialog />
    <EditBatchDialog />
</template>

<script setup>
import BatchesDashboard from "../components/batches/BatchesDashboard.vue";
import BatchesTable from "../components/batches/BatchesTable.vue";
import EditBatchDialog from "../components/batches/EditBatchDialog.vue";
import DeleteBatchDialog from "../components/batches/DeleteBatchDialog.vue";
import DuplicateBatchDialog from "../components/batches/DuplicateBatchDialog.vue";
import { useBatchStore } from "../stores/batch";

const batchStore = useBatchStore();

batchStore.fetchAllBatches();
</script>

<style scoped>
.batches-container {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}
</style>