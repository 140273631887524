<template>
    <Dialog v-model:visible="batchStore.isDuplicateDialogShown" modal header="Duplicate batch?" :closable="false"
        :style="{ width: '35rem' }">
        <span>This will create a duplicate of
            <span class="font-medium">{{ batchStore.batchToDuplicate?.label }}</span>
            including all its plots and files.
        </span>
        <div class="field mt-4">
            <label class="font-medium">New batch label</label>
            <InputText v-model="formState.newLabel" class="w-full" />
        </div>
        <template #footer>
            <Button label="Cancel" severity="secondary" @click="batchStore.hideDuplicateDialog"></Button>
            <Button label="Duplicate" class="light-green-nadar" @click="duplicateBatch"
                :loading="isDuplicateLoading"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useBatchStore } from '../../stores/batch';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const { postDuplicateBatch } = useAnalyticsAPI();
const toast = useToast();
const batchStore = useBatchStore();
const isDuplicateLoading = ref(false);
const formState = ref({
    newLabel: null
})


function resetFormState() {
    formState.value = {
        newLabel: null,
    };
}

async function duplicateBatch() {
    const trimmedLabel = formState.value.newLabel?.trim();

    if (!trimmedLabel) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing field', detail: 'Please specify a new batch label.', life: 3000 });
        return false;
    }

    try {
        isDuplicateLoading.value = true;
        const body = {
            newLabel: trimmedLabel
        }
        await postDuplicateBatch(body, batchStore.batchToDuplicate._id)
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Batch successfully duplicated.', life: 3000 });
        batchStore.fetchAllBatches();
        batchStore.hideDuplicateDialog();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to duplicate batch.', life: 3000 });
    } finally {
        isDuplicateLoading.value = false;
    }
}

watch(() => batchStore.isDuplicateDialogShown, (isVisible) => {
    if (isVisible) {
        formState.value.newLabel = `${batchStore.batchToDuplicate.label}-1`;
    } else {
        resetFormState();
    }
});
</script>