<template>
    <div class="m-2 mb-7">
        <h2>Plots</h2>
        <div v-for="({ plot }, index) in onboardingStore.plots" :key="index" class="solid-card mt-3 mb-3">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center border-round m-2">
                    <div :id="'leaflet' + index" class="map-container">
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center m-2">
                    <div>
                        <b class="flex text-xl">Plot {{ index }}</b>
                        <Badge :value="onboardingStore.plots[index].isSubmitted ? 'Submitted' : 'Not submitted'"
                            :severity="onboardingStore.plots[index].isSubmitted ? 'success' : 'warning'"></Badge>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center m-2">
                    <Button icon="pi pi-times" outlined rounded severity="danger"
                        @click="showDeletePlotDialog(index)" />
                    <Button :disabled="onboardingStore.plots[index].isSubmitted" class="ml-3" outlined rounded
                        icon="pi pi-cloud-upload" @click="handleUpload(index)" />
                </div>
            </div>
        </div>

        <div @click="pushToMapPage" v-if="onboardingStore.plots.length === 0"
            class="flex flex-column align-items-center justify-content-center dashed-card cursor-pointer">
            <Button style="color:grey;" outlined rounded icon="pi pi-plus" class="mt-2" />
            <p class="text-lg" style="color:grey;">Add a new Plot</p>
        </div>
    </div>

    <Dialog v-model:visible="submitDialog" modal :style="{ width: '90vw' }">
        <template #container="slotProps">
            <div class="surface-card border-round shadow-2 p-4">
                <div class="text-900 font-medium mb-2 text-xl">Uploading...</div>
                <p class="mt-0 mb-4 p-0 line-height-3">Please wait and do not close the browser.</p>
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="successDialog" modal :style="{ width: '90vw' }">
        <template #container="slotProps">
            <div class="surface-card border-round shadow-2 p-4">
                <div class="text-900 font-medium mb-2 text-xl">Success</div>
                <p class="mt-0 mb-4 p-0 line-height-3">You can start a new plot or close the browser.</p>
                <Button rounded class="w-full" label="OK" @click="handleSuccess"></Button>
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="errorDialog" modal :style="{ width: '90vw' }">
        <template #container="slotProps">
            <div class="surface-card border-round shadow-2 p-4">
                <div class="text-900 font-medium mb-2 text-xl">Error</div>
                <p class="mt-0 mb-4 p-0 line-height-3">Please search for internet and submit again.</p>
                <div class="flex justify-content-center">
                    <Button class="fixed-width-button w-full" rounded label="Back" @click="handleError"></Button>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="isDeletePlotDialogShown" modal :style="{ width: '90vw' }">
        <template #container="slotProps">
            <div class="surface-card border-round shadow-2 p-4">
                <div class="text-900 font-medium mb-2 text-xl">Delete Plot</div>
                <p class="mt-0 mb-4 p-0 line-height-3">Do you want to delete this plot?</p>
                <div class="flex justify-content-center">
                    <Button class="fixed-width-button w-full" rounded label="Cancel" @click="hideDeletePlotsDialog"
                        severity="secondary"></Button>
                    <Button class="fixed-width-button w-full ml-3" rounded label="Yes" @click="deletePlot"
                        severity="danger"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import { useOnboardingStore } from '../../stores/onboarding';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as Sentry from "@sentry/vue";


const { createNewPlotFromMobile } = useAnalyticsAPI();
const onboardingStore = useOnboardingStore();
const router = useRouter();
const errorDialog = ref(false);
const successDialog = ref(false);
const submitDialog = ref(false);
const toast = useToast();
const isDeletePlotDialogShown = ref(false);
const indexOfPlotToDelete = ref();


function showDeletePlotDialog(index) {
    indexOfPlotToDelete.value = index;
    isDeletePlotDialogShown.value = true;
}

function hideDeletePlotsDialog() {
    isDeletePlotDialogShown.value = false;
}

function deletePlot() {
    if (indexOfPlotToDelete.value !== undefined) {
        onboardingStore.plots = onboardingStore.plots.filter((item, index) => index !== indexOfPlotToDelete.value);
        hideDeletePlotsDialog();
    }
}
async function handleUpload(index) {
    if (!onboardingStore.plots[index].coordinates || !onboardingStore.plots[index].type) {
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Faulty plot cannot be sent.", life: 3000 });
        return;
    }

    submitDialog.value = true;

    const plotData = {
        creationType: "walked",
        geometry: {
            coordinates: onboardingStore.plots[index].coordinates,
            type: onboardingStore.plots[index].type
        },
        batch: {
            isNewBatch: false,
            batchObjectId: onboardingStore.plots[index].batchObjectId,
        },
        commodity: onboardingStore.plots[index].commodity,
        producerName: onboardingStore.plots[index].producerName,
        additionalInfo: onboardingStore.plots[index].additionalInfo,
    }

    try {
        await createNewPlotFromMobile(plotData);
    } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
            toast.add({ group: "headless", severity: 'error', summary: 'Your session expired', detail: "Please log in again.", life: 3000 });
        } else {
            Sentry.captureException(error);
            console.log(error);
            toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Could not upload plot.", life: 3000 });
        }
        onboardingStore.plots[index].isSubmitted = false;
        errorDialog.value = true;
        submitDialog.value = false;
        return;
    }

    onboardingStore.plots[index].isSubmitted = true;
    submitDialog.value = false;
    successDialog.value = true;
}

function handleSuccess() {
    successDialog.value = false;
}

function handleError() {
    errorDialog.value = false;
}

function initializeMaps() {
    onboardingStore.plots.forEach(function (plot, index) {
        const mapId = 'leaflet' + index;
        const map = L.map(mapId, {
            attributionControl: false,
            zoomControl: false,
            scrollWheelZoom: false,
            touchZoom: false,
            doubleClickZoom: false,
            boxZoom: false,
            dragging: false
        }).setView([49.03564120817072, 9.092431742145145], 14);

        L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 19,
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);
        try {
            // Swap latitude and longitude for each coordinate because Mapbox serves long lat 
            if (plot.type === "LineString") {
                const swappedCoordinates = plot.coordinates.map(coord => [coord[1], coord[0]]);
                const polyline = L.polyline(swappedCoordinates, { color: '#dfa8d9' }).addTo(map);
                const bounds = new L.LatLngBounds(swappedCoordinates);
                map.fitBounds(bounds);
            } else if (plot.type === "Point") {
                const coordinates = [plot.coordinates[1], plot.coordinates[0]]
                const circle = L.circle(coordinates, {
                    color: '#dfa8d9',
                    fillColor: '#dfa8d9',
                    fillOpacity: 1,
                    radius: 1 // Adjust the radius of the circle as needed
                }).addTo(map);
                const bounds = new L.LatLngBounds(coordinates, coordinates);
                map.fitBounds(bounds);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.log(error)
        }
    });
}

function pushToMapPage() {
    router.push({ name: 'onboarding-map' });
}


onMounted(() => {
    // Remove already submitted plots when component is rendered (again)
    onboardingStore.plots = onboardingStore.plots.filter(plot => plot.isSubmitted === false);
    initializeMaps();
});
</script>

<style scoped>
.solid-card,
.dashed-card {
    border-radius: 10px;
    border-color: grey;
    border-width: thin;
}

.solid-card {
    border-style: solid;
}

.dashed-card {
    border-style: dashed;
}

.map-container {
    border-radius: 10px;
    height: 80px;
    width: 80px;
}
</style>