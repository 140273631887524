import axios from "axios";
import { useDialogStore } from "../stores/dialog"


export function useAnalyticsAPI() {
    const VITE_CORE_SERVICE_URL = import.meta.env.VITE_CORE_SERVICE_URL;
    const dialogStore = useDialogStore();

    // Create base Axios instance for general API calls
    const axiosInstance = axios.create({
        baseURL: VITE_CORE_SERVICE_URL,
    });

    const axiosInstanceWithoutSessionExpired = axios.create({
        baseURL: VITE_CORE_SERVICE_URL,
    });

    axiosInstanceWithoutSessionExpired.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                dialogStore.isSessionExpired = true;
            }
        }
        return Promise.reject(error);
    });

    // GET

    async function getPlot(plotObjectId) {
        const response = await axiosInstance.get(`/plots/${plotObjectId}`, {
            withCredentials: true
        });
        return response.data
    }

    async function getAllTokens() {
        const response = await axiosInstance.get(`/tokens`, {
            withCredentials: true
        });
        return response.data
    }

    async function getAllFiles() {
        const response = await axiosInstance.get(`/files`, {
            withCredentials: true
        });
        return response.data
    }

    async function getDownloadFile(fileObjectId) {
        const response = await axiosInstance.get(`/files/${fileObjectId}/download`, {
            responseType: 'blob',
            withCredentials: true
        })
        return response
    }

    async function getFilesOfBatch(batchObjectId) {
        const response = await axiosInstance.get(`/files/batch/${batchObjectId}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getPollStatus(jobId) {
        const response = await axiosInstance.get(`/status/${jobId}`, {
            withCredentials: true
        })
        return response.data
    }

    async function getAllBatches() {
        const response = await axiosInstance.get(`/batches`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllDueDiligenceStatements() {
        const response = await axiosInstance.get(`/due-diligences`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getBatchByBatchObjectId(batchObjectId) {
        const response = await axiosInstance.get(`/batches/${batchObjectId}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getDueDiligenceStatementByDdsIdentifier(ddsIdentifier) {
        const response = await axiosInstance.get(`/due-diligences/${ddsIdentifier}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getSupplier(soid) {
        const response = await axiosInstance.get(`/suppliers/${soid}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getAllSuppliers() {
        const response = await axiosInstance.get(`/suppliers`, {
            withCredentials: true,
        });
        return response.data
    }

    async function patchSupplier(soid, body) {
        const response = await axiosInstance.patch(`/suppliers/${soid}`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function patchBatch(batchObjectId, body) {
        const response = await axiosInstance.patch(`/batches/${batchObjectId}`, body,
            {
                withCredentials: true,
            });

        return response.data;
    }

    async function patchPlot(plotObjectId, body) {
        const response = await axiosInstance.patch(`/plots/${plotObjectId}`, body,
            {
                withCredentials: true,
            });

        return response.data;
    }

    //POST

    async function updatePlotGeometry(plotObjectId, body) {
        const response = await axiosInstance.post(`/plots/${plotObjectId}/geometry`, body,
            {
                withCredentials: true,
            });

        return response.data;
    }

    async function postSupplier(body) {
        const response = await axiosInstance.post(`/suppliers`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postDuplicateBatch(body, batchObjectId) {
        const response = await axiosInstance.post(`/batches/${batchObjectId}/duplicate`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postCreatePlotsWithImport(body) {
        const response = await axiosInstance.post(`/plots/import`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function createDueDiligenceStatement(body) {
        const response = await axiosInstance.post(`/due-diligences`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function replaceGeometryAndRestartRiskDetection(body) {
        const response = await axiosInstance.post(`/plots/risk/restart/geometry`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function restartRiskDetection(body) {
        const response = await axiosInstance.post(`/plots/risk/restart`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function getPaginationPlots(body) {
        const response = await axiosInstance.post(`/plots/pagination/plots`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getPaginationBatchPlots(body) {
        const response = await axiosInstance.post(`/plots/pagination/batch`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function createNewApiToken() {
        const response = await axiosInstance.post(`/tokens`, null, {  // Body is null
            withCredentials: true,
        });
        return response.data;
    }

    async function postCreatePlot(body) {
        const response = await axiosInstance.post(`/plots`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    }

    async function createNewPlotFromMobile(upload_data) {
        const response = await axiosInstanceWithoutSessionExpired.post(`/plots`, upload_data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    }

    async function exportPlotsGeojson(body) {
        const response = await axiosInstance.post(`/plots/export`, body, {
            withCredentials: true,
        });

        return response.data
    }

    async function exportPlotsExcel(body) {
        const response = await axiosInstance.post(`/plots/export`, body, {
            responseType: 'blob',
            withCredentials: true
        });

        return response.data
    }

    async function postRetractDueDiligenceStatement(body) {
        const response = await axiosInstance.post(`/due-diligences/retract`, body, {
            withCredentials: true
        });

        return response.data
    }

    // DELETE

    async function deletePaginationPlots(body) {
        const response = await axiosInstance.post(`/plots/pagination/batch/delete`, body, {
            withCredentials: true
        });

        return response;
    }

    async function deleteToken(token) {
        const response = await axiosInstance.delete(`/tokens/${token}`, {
            withCredentials: true
        });

        return response.data;
    }

    async function deletePlots(body) {
        const response = await axiosInstance.post(`/plots/delete`, body, {
            withCredentials: true
        });

        return response;
    }

    async function deleteBatch(batchObjectId) {
        const response = await axiosInstance.delete(`/batches/${batchObjectId}`, {
            withCredentials: true
        });

        return response.data;
    }

    async function deleteFile(fileObjectId) {
        const response = await axiosInstance.delete(`/files/${fileObjectId}`, {
            withCredentials: true
        });

        return response.data;
    }

    async function deleteSupplier(supplierObjectId) {
        const response = await axiosInstance.delete(`/suppliers/${supplierObjectId}`, {
            withCredentials: true
        });
        return response.data
    }


    return {
        getPlot,
        getDueDiligenceStatementByDdsIdentifier,
        getDownloadFile,
        getBatchByBatchObjectId,
        getPaginationBatchPlots,
        getPollStatus,
        getFilesOfBatch,
        getAllFiles,
        getAllBatches,
        patchSupplier,
        deleteSupplier,
        postSupplier,
        postDuplicateBatch,
        getAllSuppliers,
        getSupplier,
        exportPlotsExcel,
        exportPlotsGeojson,
        patchBatch,
        patchPlot,
        updatePlotGeometry,
        postCreatePlotsWithImport,
        replaceGeometryAndRestartRiskDetection,
        restartRiskDetection,
        getPaginationPlots,
        getAllTokens,
        createNewApiToken,
        getAllDueDiligenceStatements,
        createNewPlotFromMobile,
        postCreatePlot,
        createDueDiligenceStatement,
        postRetractDueDiligenceStatement,
        deleteToken,
        deletePaginationPlots,
        deletePlots,
        deleteBatch,
        deleteFile,
    }
}
