<template>
    <Dialog v-model:visible="plotStore.isDeleteDialogShown" modal header="Delete plots?" :closable="false"
        :style="{ width: '30rem' }">
        <span>Selected plots will be deleted. You can't undo this action.</span>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-2" @click="plotStore.hideDeleteDialog"></Button>
            <Button label="Delete" severity="danger" @click="handleDeletePlots" :loading="isDeleting"></Button>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import * as Sentry from "@sentry/vue";
import { useAnalyticsAPI } from '../../../composables/useAnalyticsAPI';
import { usePlotStore } from '../../../stores/plot';
import { usePlotRiskUtils } from './composables/usePlotRiskUtils';


const { deletePaginationPlots } = useAnalyticsAPI();
const { refreshData } = usePlotRiskUtils();
const route = useRoute();
const plotStore = usePlotStore();
const toast = useToast();
const isDeleting = ref(false);


async function handleDeletePlots() {
    const processedFilters = {}

    for (const [filterField, filterConfig] of Object.entries(plotStore.filters)) {
        if (filterConfig.value !== null) {
            if (filterField === "dataQuality") {
                const dataQuality = [];
                for (const value of filterConfig.value) {
                    dataQuality.push(value.code)
                }
                processedFilters[filterField] = dataQuality;
            } else if (filterField === "deforestationJrc") {
                const deforestationJrc = [];
                for (const value of filterConfig.value) {
                    deforestationJrc.push(value.code)
                }
                processedFilters[filterField] = deforestationJrc;
            } else if (filterField === "deforestationNadar") {
                const deforestationNadar = [];
                for (const value of filterConfig.value) {
                    deforestationNadar.push(value.code)
                }
                processedFilters[filterField] = deforestationNadar;
            } else if (filterField === "legality") {
                const legality = [];
                for (const value of filterConfig.value) {
                    legality.push(value.code)
                }
                processedFilters[filterField] = legality;
            } else if (filterField === "supplier") {
                const supplier = [];
                for (const value of filterConfig.value) {
                    supplier.push(value._id)
                }
                processedFilters[filterField] = supplier;
            }
            else {
                processedFilters[filterField] = filterConfig.value;
            }
        }
    }

    const payload = {
        batchObjectId: route.params.batchObjectId,
        plotObjectIds: plotStore.selectedCheckboxes,
        isEveryPageSelected: plotStore.isEveryPageSelected,
        filters: JSON.stringify(processedFilters)
    }

    try {
        isDeleting.value = true;
        const response = await deletePaginationPlots(payload);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Plots successfully deleted.', life: 3000 });
        refreshData();
        plotStore.hideDeleteDialog();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to delete plots.', life: 3000 });
        return;
    } finally {
        isDeleting.value = false;
    }
}
</script>