export const HELP_CENTER_LINK = "https://nadar.zohodesk.eu/portal"
export const SUPPORT_LINK = "https://share-eu1.hsforms.com/1KTm3Mt1uTTCnZhOaSA3d3A2dtpbh"

export const GEOMETRY_MODE = {
    NONE: 'NONE',
    SEARCHING: 'SEARCHING',
    IMPORTING: 'IMPORTING',
    DRAWING: 'DRAWING',
    MARKING: 'MARKING',
    EDITING: 'EDITING'
};

export const IMPORT_MODE = {
    IDLE: "IDLE", // wenn nichts importiert wurde und select file angezeigt wird
    IMPORT: "IMPORT", // wenn das file erfolgreich durchs parsing ging und wkt oder lng lat ausgewählt wurde
}

export const UNITS = [
    { label: "% vol", code: "ASV" },
    { label: "% vol/hl", code: "ASVX" },
    { label: "ct/l", code: "CCT" },
    { label: "100 p/st", code: "CEN" },
    { label: "c/k", code: "CTM" },
    { label: "10 000 kg/polar", code: "DAP" },
    { label: "kg DHS", code: "DHS" },
    { label: "100 kg", code: "DTN" },
    { label: "100 kg/net eda", code: "DTNE" },
    { label: "100 kg common wheat", code: "DTNF" },
    { label: "100 kg/br", code: "DTNG" },
    { label: "100 kg live weight", code: "DTNL" },
    { label: "100 kg/net mas", code: "DTNM" },
    { label: "100 kg std qual", code: "DTNR" },
    { label: "100 kg raw sugar", code: "DTNS" },
    { label: "100 kg/net/%sacchar.", code: "DTNZ" },
    { label: "1 EUR/Nat Curr", code: "ENP" },
    { label: "EUR", code: "EUR" },
    { label: "gi F/S", code: "GFI" },
    { label: "g", code: "GRM" },
    { label: "GT", code: "GRT" },
    { label: "hl", code: "HLT" },
    { label: "100 m", code: "HMT" },
    { label: "Kg net Ace K", code: "KAC" },
    { label: "kg C₅H₁₄ClNO", code: "KCC" },
    { label: "tonne KCl", code: "KCL" },
    { label: "kg", code: "KGM" },
    { label: "kg/tot/alc", code: "KGMA" },
    { label: "kg/net eda", code: "KGME" },
    { label: "kg/br", code: "KGMG" },
    { label: "kg/lactic matter", code: "KGMP" },
    { label: "kg/raw sugar", code: "KGMS" },
    { label: "kg/dry lactic matter", code: "KGMT" },
    { label: "1000 l", code: "KLT" },
    { label: "kg methylamines", code: "KMA" },
    { label: "KM", code: "KMT" },
    { label: "kg N", code: "KNI" },
    { label: "kg H₂O₂", code: "KNS" },
    { label: "kg KOH", code: "KPH" },
    { label: "kg K₂O", code: "KPO" },
    { label: "kg P₂O₅", code: "KPP" },
    { label: "kg 90% sdt", code: "KSD" },
    { label: "kg NaOH", code: "KSH" },
    { label: "kg U", code: "KUR" },
    { label: "l alc. 100%", code: "LPA" },
    { label: "l", code: "LTR" },
    { label: "L total alc.", code: "LTRA" },
    { label: "1000 p/st", code: "MIL" },
    { label: "1000 pa", code: "MPR" },
    { label: "m²", code: "MTK" },
    { label: "m³", code: "MTQ" },
    { label: "1000 m³", code: "MTQC" },
    { label: "m", code: "MTR" },
    { label: "1000 kWh", code: "MWH" },
    { label: "p/st", code: "NAR" },
    { label: "b/f", code: "NARB" },
    { label: "ce/el", code: "NCL" },
    { label: "pa", code: "NPR" },
    { label: "TJ", code: "TJO" },
    { label: "1000 kg", code: "TNE" },
    { label: "1000 kg/net eda", code: "TNEE" },
    { label: "1000 kg/biodiesel", code: "TNEI" },
    { label: "1000 kg/fuel content", code: "TNEJ" },
    { label: "1000 kg/bioethanol", code: "TNEK" },
    { label: "1000 kg/net mas", code: "TNEM" },
    { label: "1000 kg std qual", code: "TNER" },
    { label: "1000 kg/net/%sacchar.", code: "TNEZ" },
    { label: "Watt", code: "WAT" }
]

export const HS_CODES = [
    {
        chapter: "01 - Live Animals",
        commodities: [
            {
                title: "0102 21",
                code: "010221",
                description: "Live cattle"
            },
            {
                title: "0102 29",
                code: "010229",
                description: "Other"
            }
        ]
    },
    {
        chapter: "02 - Meat and Edible Meat Offal",
        commodities: [
            {
                title: "0201",
                code: "0201",
                description: "Meat of cattle, fresh or chilled"
            },
            {
                title: "0202",
                code: "0202",
                description: "Meat of cattle, frozen"
            },
            {
                title: "0206 10",
                code: "020610",
                description: "Edible offal of cattle, fresh or chilled"
            },
            {
                title: "0206 22",
                code: "020622",
                description: "Edible cattle livers, frozen"
            },
            {
                title: "0206 29",
                code: "020629",
                description: "Edible cattle offal (excluding tongues and livers), frozen"
            },
        ]
    },
    {
        chapter: "09 - Coffee, Tea, Maté and Spices",
        commodities: [
            {
                title: "0901",
                code: "0901",
                description: "Coffee, whether or not roasted or decaffeinated; coffee husks and skins"
            }
        ]
    },
    {
        chapter: "12 - Oil Seeds and Oleaginous Fruits; Miscellaneous Grains, Seeds and Fruit; Industrial or Medicinal Plants; Straw and Fodder",
        commodities: [
            {
                title: "1201",
                code: "1201",
                description: "Soya beans, whether or not broken"
            },
            {
                title: "1207 10",
                code: "120710",
                description: "Palm nuts and kernels"
            },
            {
                title: "1208 10",
                code: "120810",
                description: "Soya bean flour and meal"
            }
        ]
    },
    {
        chapter: "15 - Animal or Vegetable Fats and Oils and Their Cleavage codes; Prepared Edible Fats; Animal or Vegetable Waxes",
        commodities: [
            {
                title: "1507",
                code: "1507",
                description: "Soya-bean oil and its fractions, whether or not refined, but not chemically modified"
            },
            {
                title: "1511",
                code: "1511",
                description: "Palm oil and its fractions, whether or not refined, but not chemically modified"
            },
            {
                title: "1513 21",
                code: "151321",
                description: "Crude palm kernel and babassu oil"
            },
            {
                title: "1513 29",
                code: "151329",
                description: "Palm kernel and babassu oil and their fractions"
            }
        ]
    },
    {
        chapter: "16 - Preparations of Meat, of Fish or of Crustaceans, Molluscs or Other Aquatic Invertebrates",
        commodities: [
            {
                title: "1602 50",
                code: "160250",
                description: "Other prepared or preserved meat, meat offal or blood (Of bovine animals)"
            }
        ]
    },
    {
        chapter: "18 - Cocoa and Cocoa Preparations",
        commodities: [
            {
                title: "1801",
                code: "1801",
                description: "Cocoa beans, whole or broken, raw or roasted"
            },
            {
                title: "1802",
                code: "1802",
                description: "Cocoa shells, husks, skins and other cocoa waste"
            },
            {
                title: "1803",
                code: "1803",
                description: "Cocoa paste, whether or not defatted"
            },
            {
                title: "1804",
                code: "1804",
                description: "Cocoa butter, fat and oil"
            },
            {
                title: "1805",
                code: "1805",
                description: "Cocoa powder, not containing added sugar or other sweetening matter"
            },
            {
                title: "1806",
                code: "1806",
                description: "Chocolate and other food preparations containing cocoa"
            }
        ]
    },
    {
        chapter: "23 - Residues and Waste from the Food Industries; Prepared Animal Fodder",
        commodities: [
            {
                title: "2304",
                code: "2304",
                description: "Oilcake and solid residues from extraction of soya-bean oil"
            },
            {
                title: "2306 60",
                code: "230660",
                description: "Oilcake and other solid residues of palm nuts or kernels"
            }
        ]
    },
    {
        chapter: "29 - Organic Chemicals",
        commodities: [
            {
                title: "2905 45",
                code: "290545",
                description: "Glycerol, with a purity of 95% or more"
            },
            {
                title: "2915 70",
                code: "291570",
                description: "Palmitic acid, stearic acid, their salts and esters"
            },
            {
                title: "2915 90",
                code: "291590",
                description: "Saturated acyclic monocarboxylic acids and their derivatives"
            }
        ]
    },
    {
        chapter: "38 - Miscellaneous Chemical Products",
        commodities: [
            {
                title: "3823 11",
                code: "382311",
                description: "Stearic acid, industrial"
            },
            {
                title: "3823 12",
                code: "382312",
                description: "Oleic acid, industrial"
            },
            {
                title: "3823 19",
                code: "382319",
                description: "Industrial monocarboxylic fatty acids; acid oils from refining"
            },
            {
                title: "3823 70",
                code: "382370",
                description: "Industrial fatty alcohols"
            }
        ]
    },
    {
        chapter: "40 - Rubber and Articles Thereof",
        commodities: [
            {
                title: "4001",
                code: "4001",
                description: "Natural rubber, balata, gutta-percha, guayule, chicle and similar natural gums"
            },
            {
                title: "4005",
                code: "4005",
                description: "Compounded rubber, unvulcanised, in primary forms or in plates, sheets or strip"
            },
            {
                title: "4006",
                code: "4006",
                description: "Unvulcanised rubber in other forms and articles"
            },
            {
                title: "4007",
                code: "4007",
                description: "Vulcanised rubber thread and cord"
            },
            {
                title: "4008",
                code: "4008",
                description: "Plates, sheets, strips, rods and profile shapes, of vulcanised rubber"
            },
            {
                title: "4010",
                code: "4010",
                description: "Conveyer or transmission belts or belting, of vulcanised rubber"
            },
            {
                title: "4011",
                code: "4011",
                description: "New pneumatic tyres, of rubber"
            },
            {
                title: "4012",
                code: "4012",
                description: "Retreaded or used pneumatic tyres of rubber"
            },
            {
                title: "4013",
                code: "4013",
                description: "Inner tubes, of rubber"
            },
            {
                title: "4015",
                code: "4015",
                description: "Articles of apparel and clothing accessories, of vulcanised rubber"
            },
            {
                title: "4016",
                code: "4016",
                description: "Other articles of vulcanised rubber, not elsewhere specified"
            },
            {
                title: "4017",
                code: "4017",
                description: "Hard rubber in all forms including waste and scrap; articles of hard rubber"
            },
        ]
    },
    {
        chapter: "41 - Raw Hides and Skins (Other Than Furskins) and Leather",
        commodities: [
            {
                title: "4101",
                code: "4101",
                description: "Raw hides and skins of cattle"
            },
            {
                title: "4104",
                code: "4104",
                description: "Tanned or crust hides and skins of cattle"
            },
            {
                title: "4107",
                code: "4107",
                description: "Leather of cattle, further prepared after tanning"
            }
        ]
    },
    {
        chapter: "44 - Wood and Articles of Wood; Wood Charcoal",
        commodities: [
            {
                title: "4401",
                code: "4401",
                description: "Fuel wood, in logs, in billets, in twigs, in faggots, or similar forms"
            },
            {
                title: "4402",
                code: "4402",
                description: "Wood charcoal, whether or not agglomerated"
            },
            {
                title: "4403",
                code: "4403",
                description: "Wood in the rough, whether or not stripped of bark or sapwood"
            },
            {
                title: "4404",
                code: "4404",
                description: "Hoopwood; split poles; piles, pickets, and stakes of wood"
            },
            {
                title: "4405",
                code: "4405",
                description: "Wood wool; wood flour"
            },
            {
                title: "4406",
                code: "4406",
                description: "Railway or tramway sleepers of wood"
            },
            {
                title: "4407",
                code: "4407",
                description: "Wood sawn or chipped lengthwise, sliced or peeled, whether or not planed, sanded or end-jointed"
            },
            {
                title: "4408",
                code: "4408",
                description: "Sheets for veneering, for plywood or for similar laminated wood, of thickness not exceeding 6 mm"
            },
            {
                title: "4409",
                code: "4409",
                description: "Wood (including strips and friezes for parquet flooring), continuously shaped along edges, ends, or faces"
            },
            {
                title: "4410",
                code: "4410",
                description: "Particle board, oriented strand board (OSB), and similar board of wood"
            },
            {
                title: "4411",
                code: "4411",
                description: "Fibreboard of wood or other ligneous materials, bonded with resins or other organic substances"
            },
            {
                title: "4412",
                code: "4412",
                description: "Plywood, veneered panels, and similar laminated wood"
            },
            {
                title: "4413",
                code: "4413",
                description: "Densified wood, in blocks, plates, strips, or profile shapes"
            },
            {
                title: "4414",
                code: "4414",
                description: "Wooden frames for paintings, photographs, mirrors or similar objects"
            },
            {
                title: "4415",
                code: "4415",
                description: "Packing cases, boxes, crates, drums and similar packings, of wood (not including packing material used exclusively as packing material to support, protect or carry another product placed on the market)"
            },
            {
                title: "4416",
                code: "4416",
                description: "Casks, barrels, vats, tubs and other coopers’ products and parts thereof, of wood, including staves"
            },
            {
                title: "4417",
                code: "4417",
                description: "Tools, tool bodies, tool handles, broom or brush bodies, and boot or shoe lasts of wood"
            },
            {
                title: "4418",
                code: "4418",
                description: "Builders' joinery and carpentry of wood, including cellular wood panels, shingles, and shakes"
            },
            {
                title: "4419",
                code: "4419",
                description: "Tableware and kitchenware, of wood"
            },
            {
                title: "4420",
                code: "4420",
                description: "Wood marquetry and inlaid wood; caskets and cases for jewellery or cutlery, and similar articles of wood"
            },
            {
                title: "4421",
                code: "4421",
                description: "Other articles of wood"
            }
        ]
    },
    {
        chapter: "47 - Pulp of Wood or of Other Fibrous Cellulosic Material; Recovered (Waste and Scrap) Paper or Paperboard",
        commodities: [
            {
                title: "4701",
                code: "4701",
                description: "Mechanical wood pulp"
            },
            {
                title: "4702",
                code: "4702",
                description: "Chemical wood pulp, dissolving grades"
            },
            {
                title: "4703",
                code: "4703",
                description: "Chemical wood pulp, soda or sulphate, other than dissolving grades"
            },
            {
                title: "4704",
                code: "4704",
                description: "Chemical wood pulp, sulphite, other than dissolving grades"
            },
            {
                title: "4705",
                code: "4705",
                description: "Semichemical wood pulp"
            }
        ]
    },
    {
        chapter: "48 - Paper and Paperboard; Articles of Paper Pulp, of Paper or of Paperboard",
        commodities: [
            {
                title: "4802",
                code: "4802",
                description: "Uncoated paper and paperboard, used for writing, printing or other graphic purposes, handmade paper and paperboard"
            },
            {
                title: "4803",
                code: "4803",
                description: "Toilet or facial tissue stock, towel or napkin stock and similar paper, cellulose wadding, webs of cellulose fibres"
            },
            {
                title: "4804",
                code: "4804",
                description: "Uncoated kraft paper and paperboard, in rolls or sheets"
            },
            {
                title: "4805",
                code: "4805",
                description: "Other uncoated paper and paperboard, not further worked or processed than specified"
            },
            {
                title: "4806",
                code: "4806",
                description: "Vegetable parchment, greaseproof papers, tracing papers, glassine and other glazed transparent or translucent papers"
            },
            {
                title: "4807",
                code: "4807",
                description: "Composite paper and paperboard, not surface-coated or impregnated"
            },
            {
                title: "4808",
                code: "4808",
                description: "Paper and paperboard, corrugated, creped, crinkled, embossed, perforated"
            },
            {
                title: "4809",
                code: "4809",
                description: "Carbon paper, self-copy paper and other copying or transfer papers"
            },
            {
                title: "4810",
                code: "4810",
                description: "Paper and paperboard, coated on one or both sides with kaolin or other inorganic substances"
            },
            {
                title: "4811",
                code: "4811",
                description: "Paper, paperboard, cellulose wadding and webs of cellulose fibres, coated, impregnated, surface-coloured"
            },
            {
                title: "4812",
                code: "4812",
                description: "Filter blocks, slabs and plates, of paper pulp"
            },
            {
                title: "4813",
                code: "4813",
                description: "Cigarette paper, whether or not cut to size or in the form of booklets or tubes"
            },
            {
                title: "4814",
                code: "4814",
                description: "Wallpaper and similar wallcoverings; window transparencies of paper"
            },
            {
                title: "4816",
                code: "4816",
                description: "Carbon paper, self-copy paper and other copying or transfer papers, duplicator stencils, offset plates of paper"
            },
            {
                title: "4817",
                code: "4817",
                description: "Envelopes, letter cards, plain postcards and correspondence cards; boxes, pouches, wallets, writing compendiums"
            },
            {
                title: "4818",
                code: "4818",
                description: "Toilet paper and similar paper, cellulose wadding or webs of cellulose fibres, handkerchiefs, cleansing tissues, towels, etc."
            },
            {
                title: "4819",
                code: "4819",
                description: "Cartons, boxes, cases, bags and other packing containers; box files, letter trays, and similar articles of paper or paperboard"
            },
            {
                title: "4821",
                code: "4821",
                description: "Paper or paperboard labels of all kinds, whether or not printed"
            },
            {
                title: "4823",
                code: "4823",
                description: "Other paper, paperboard, cellulose wadding, cut to size or shape; other articles of paper pulp, paperboard"
            }
        ]
    },
    {
        chapter: "49 - Printed Books, Newspapers, Pictures and Other Products of the Printing Industry",
        commodities: [
            {
                title: "49",
                code: "49",
                description: "Printed books, newspapers, pictures and other products of the printing industry, manuscripts, typescripts and plans"
            }
        ]
    },
    {
        chapter: "94 - Furniture; Bedding, Mattresses, Mattress Supports, Cushions, and Similar Stuffed Furnishings; Lamps and Lighting Fittings",
        commodities: [
            {
                title: "9401",
                code: "9401",
                description: "Seats (other than those of heading 9402), whether or not convertible into beds, and parts thereof"
            },
            {
                title: "9403 30",
                code: "940330",
                description: "Wooden furniture of a kind used in offices"
            },
            {
                title: "9403 40",
                code: "940340",
                description: "Wooden furniture of a kind used in the kitchen"
            },
            {
                title: "9403 50",
                code: "940350",
                description: "Wooden furniture of a kind used in the bedroom"
            },
            {
                title: "9403 60",
                code: "940360",
                description: "Other wooden furniture"
            },
            {
                title: "9403 91",
                code: "940391",
                description: "Parts of wooden furniture"
            },
            {
                title: "9406 10",
                code: "940610",
                description: "Prefabricated buildings of wood"
            }
        ]
    }
]

export const COMMODITIES = [
    "Cattle", "Cocoa", "Coffee", "Oil palm", "Rubber", "Soya", "Wood"
];

export const DATA_QUALITY_FILTER = [
    { label: 'Area limit', code: 'area_limit' },
    { label: 'Built area', code: 'built_area' },
    { label: 'Close points', code: 'close_points' },
    { label: 'Duplicates', code: 'duplicates' },
    { label: 'Low risk', code: 'low_risk' },
    { label: 'Overlaps', code: 'overlaps' },
    { label: 'Plot in ocean', code: 'plot_in_ocean' },
    { label: 'Self-Intersect', code: 'self_intersect' },
    { label: 'Small area', code: 'small_area' },
    { label: 'Spikes', code: 'spikes' }
];

export const EU_DEFORESTATION_FILTER = [
    { label: 'High risk', code: 'high_risk' },
    { label: 'Low risk', code: 'low_risk' },
    { label: 'Not checked', code: 'not_checked' }
];

export const NADAR_DEFORESTATION_FILTER = [
    { label: 'High risk', code: 'high_risk' },
    { label: 'Low risk', code: 'low_risk' },
    { label: 'Not checked', code: 'not_checked' }
];

export const LEGALITY_FILTER = [
    { label: 'Indigenous lands', code: 'indigenous_lands' },
    { label: 'Low risk', code: 'low_risk' },
    { label: 'Protected areas', code: 'protected_areas' }
];

export const GEOMETRY_FILTER = ["Polygon", "Point"]

export const COUNTRIES = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Côte d'Ivoire", "Cabo Verde",
    "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo",
    "Republic of Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini (fmr. \"Swaziland\")", "Ethiopia",
    "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
    "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica",
    "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait",
    "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
    "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
    "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
    "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar (formerly Burma)", "Namibia", "Nauru",
    "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea",
    "North Macedonia (formerly Macedonia)", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland",
    "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago",
    "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
    "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela",
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

export const ALLOWED_COUNTRIES = [
    "Austria",        // AT
    "Belgium",        // BE
    "Bulgaria",       // BG
    "Cyprus",         // CY
    "Czech Republic", // CZ
    "Germany",        // DE
    "Denmark",        // DK
    "Estonia",        // EE
    "Spain",          // ES
    "Finland",        // FI
    "France",         // FR
    "Greece",         // GR
    "Croatia",        // HR
    "Hungary",        // HU
    "Ireland",        // IE
    "Italy",          // IT
    "Lithuania",      // LT
    "Luxembourg",     // LU
    "Latvia",         // LV
    "Malta",          // MT
    "Netherlands",    // NL
    "Poland",         // PL
    "Portugal",       // PT
    "Romania",        // RO
    "Sweden",         // SE
    "Slovenia",       // SI
    "Slovakia",       // SK
    "United Kingdom (Northern Ireland)" // XI
];

export const ALLOWED_COUNTRIES_WITH_ISO = [
    { label: "Austria", code: "AT" },
    { label: "Belgium", code: "BE" },
    { label: "Bulgaria", code: "BG" },
    { label: "Cyprus", code: "CY" },
    { label: "Czech Republic", code: "CZ" },
    { label: "Germany", code: "DE" },
    { label: "Denmark", code: "DK" },
    { label: "Estonia", code: "EE" },
    { label: "Spain", code: "ES" },
    { label: "Finland", code: "FI" },
    { label: "France", code: "FR" },
    { label: "Greece", code: "GR" },
    { label: "Croatia", code: "HR" },
    { label: "Hungary", code: "HU" },
    { label: "Ireland", code: "IE" },
    { label: "Italy", code: "IT" },
    { label: "Lithuania", code: "LT" },
    { label: "Luxembourg", code: "LU" },
    { label: "Latvia", code: "LV" },
    { label: "Malta", code: "MT" },
    { label: "Netherlands", code: "NL" },
    { label: "Poland", code: "PL" },
    { label: "Portugal", code: "PT" },
    { label: "Romania", code: "RO" },
    { label: "Sweden", code: "SE" },
    { label: "Slovenia", code: "SI" },
    { label: "Slovakia", code: "SK" },
    { label: "United Kingdom (Northern Ireland)", code: "XI" }
];

export const COUNTRIES_WITH_ISO = [
    { label: "Afghanistan", code: "AF" },
    { label: "Albania", code: "AL" },
    { label: "Algeria", code: "DZ" },
    { label: "Andorra", code: "AD" },
    { label: "Angola", code: "AO" },
    { label: "Antigua and Barbuda", code: "AG" },
    { label: "Argentina", code: "AR" },
    { label: "Armenia", code: "AM" },
    { label: "Australia", code: "AU" },
    { label: "Austria", code: "AT" },
    { label: "Azerbaijan", code: "AZ" },
    { label: "Bahamas", code: "BS" },
    { label: "Bahrain", code: "BH" },
    { label: "Bangladesh", code: "BD" },
    { label: "Barbados", code: "BB" },
    { label: "Belarus", code: "BY" },
    { label: "Belgium", code: "BE" },
    { label: "Belize", code: "BZ" },
    { label: "Benin", code: "BJ" },
    { label: "Bhutan", code: "BT" },
    { label: "Bolivia", code: "BO" },
    { label: "Bosnia and Herzegovina", code: "BA" },
    { label: "Botswana", code: "BW" },
    { label: "Brazil", code: "BR" },
    { label: "Brunei", code: "BN" },
    { label: "Bulgaria", code: "BG" },
    { label: "Burkina Faso", code: "BF" },
    { label: "Burundi", code: "BI" },
    { label: "Côte d'Ivoire", code: "CI" },
    { label: "Cabo Verde", code: "CV" },
    { label: "Cambodia", code: "KH" },
    { label: "Cameroon", code: "CM" },
    { label: "Canada", code: "CA" },
    { label: "Central African Republic", code: "CF" },
    { label: "Chad", code: "TD" },
    { label: "Chile", code: "CL" },
    { label: "China", code: "CN" },
    { label: "Colombia", code: "CO" },
    { label: "Comoros", code: "KM" },
    { label: "Costa Rica", code: "CR" },
    { label: "Croatia", code: "HR" },
    { label: "Cuba", code: "CU" },
    { label: "Cyprus", code: "CY" },
    { label: "Czech Republic", code: "CZ" },
    { label: "Democratic Republic of the Congo", code: "CD" },
    { label: "Republic of Congo", code: "CG" },
    { label: "Denmark", code: "DK" },
    { label: "Djibouti", code: "DJ" },
    { label: "Dominica", code: "DM" },
    { label: "Dominican Republic", code: "DO" },
    { label: "Ecuador", code: "EC" },
    { label: "Egypt", code: "EG" },
    { label: "El Salvador", code: "SV" },
    { label: "Equatorial Guinea", code: "GQ" },
    { label: "Eritrea", code: "ER" },
    { label: "Estonia", code: "EE" },
    { label: "Eswatini (fmr. \"Swaziland\")", code: "SZ" },
    { label: "Ethiopia", code: "ET" },
    { label: "Fiji", code: "FJ" },
    { label: "Finland", code: "FI" },
    { label: "France", code: "FR" },
    { label: "Gabon", code: "GA" },
    { label: "Gambia", code: "GM" },
    { label: "Georgia", code: "GE" },
    { label: "Germany", code: "DE" },
    { label: "Ghana", code: "GH" },
    { label: "Greece", code: "GR" },
    { label: "Grenada", code: "GD" },
    { label: "Guatemala", code: "GT" },
    { label: "Guinea", code: "GN" },
    { label: "Guinea-Bissau", code: "GW" },
    { label: "Guyana", code: "GY" },
    { label: "Haiti", code: "HT" },
    { label: "Holy See", code: "VA" },
    { label: "Honduras", code: "HN" },
    { label: "Hungary", code: "HU" },
    { label: "Iceland", code: "IS" },
    { label: "India", code: "IN" },
    { label: "Indonesia", code: "ID" },
    { label: "Iran", code: "IR" },
    { label: "Iraq", code: "IQ" },
    { label: "Ireland", code: "IE" },
    { label: "United Kingdom (Northern Ireland)", code: "XI" },
    { label: "Israel", code: "IL" },
    { label: "Italy", code: "IT" },
    { label: "Jamaica", code: "JM" },
    { label: "Japan", code: "JP" },
    { label: "Jordan", code: "JO" },
    { label: "Kazakhstan", code: "KZ" },
    { label: "Kenya", code: "KE" },
    { label: "Kiribati", code: "KI" },
    { label: "Kuwait", code: "KW" },
    { label: "Kyrgyzstan", code: "KG" },
    { label: "Laos", code: "LA" },
    { label: "Latvia", code: "LV" },
    { label: "Lebanon", code: "LB" },
    { label: "Lesotho", code: "LS" },
    { label: "Liberia", code: "LR" },
    { label: "Libya", code: "LY" },
    { label: "Liechtenstein", code: "LI" },
    { label: "Lithuania", code: "LT" },
    { label: "Luxembourg", code: "LU" },
    { label: "Madagascar", code: "MG" },
    { label: "Malawi", code: "MW" },
    { label: "Malaysia", code: "MY" },
    { label: "Maldives", code: "MV" },
    { label: "Mali", code: "ML" },
    { label: "Malta", code: "MT" },
    { label: "Marshall Islands", code: "MH" },
    { label: "Mauritania", code: "MR" },
    { label: "Mauritius", code: "MU" },
    { label: "Mexico", code: "MX" },
    { label: "Micronesia", code: "FM" },
    { label: "Moldova", code: "MD" },
    { label: "Monaco", code: "MC" },
    { label: "Mongolia", code: "MN" },
    { label: "Montenegro", code: "ME" },
    { label: "Morocco", code: "MA" },
    { label: "Mozambique", code: "MZ" },
    { label: "Myanmar (formerly Burma)", code: "MM" },
    { label: "Namibia", code: "NA" },
    { label: "Nauru", code: "NR" },
    { label: "Nepal", code: "NP" },
    { label: "Netherlands", code: "NL" },
    { label: "New Zealand", code: "NZ" },
    { label: "Nicaragua", code: "NI" },
    { label: "Niger", code: "NE" },
    { label: "Nigeria", code: "NG" },
    { label: "North Korea", code: "KP" },
    { label: "North Macedonia (formerly Macedonia)", code: "MK" },
    { label: "Norway", code: "NO" },
    { label: "Oman", code: "OM" },
    { label: "Pakistan", code: "PK" },
    { label: "Palau", code: "PW" },
    { label: "Palestine State", code: "PS" },
    { label: "Panama", code: "PA" },
    { label: "Papua New Guinea", code: "PG" },
    { label: "Paraguay", code: "PY" },
    { label: "Peru", code: "PE" },
    { label: "Philippines", code: "PH" },
    { label: "Poland", code: "PL" },
    { label: "Portugal", code: "PT" },
    { label: "Qatar", code: "QA" },
    { label: "Romania", code: "RO" },
    { label: "Russia", code: "RU" },
    { label: "Rwanda", code: "RW" },
    { label: "Saint Kitts and Nevis", code: "KN" },
    { label: "Saint Lucia", code: "LC" },
    { label: "Saint Vincent and the Grenadines", code: "VC" },
    { label: "Samoa", code: "WS" },
    { label: "San Marino", code: "SM" },
    { label: "Sao Tome and Principe", code: "ST" },
    { label: "Saudi Arabia", code: "SA" },
    { label: "Senegal", code: "SN" },
    { label: "Serbia", code: "RS" },
    { label: "Seychelles", code: "SC" },
    { label: "Sierra Leone", code: "SL" },
    { label: "Singapore", code: "SG" },
    { label: "Slovakia", code: "SK" },
    { label: "Slovenia", code: "SI" },
    { label: "Solomon Islands", code: "SB" },
    { label: "Somalia", code: "SO" },
    { label: "South Africa", code: "ZA" },
    { label: "South Korea", code: "KR" },
    { label: "South Sudan", code: "SS" },
    { label: "Spain", code: "ES" },
    { label: "Sri Lanka", code: "LK" },
    { label: "Sudan", code: "SD" },
    { label: "Suriname", code: "SR" },
    { label: "Sweden", code: "SE" },
    { label: "Switzerland", code: "CH" },
    { label: "Syria", code: "SY" },
    { label: "Tajikistan", code: "TJ" },
    { label: "Tanzania", code: "TZ" },
    { label: "Thailand", code: "TH" },
    { label: "Timor-Leste", code: "TL" },
    { label: "Togo", code: "TG" },
    { label: "Tonga", code: "TO" },
    { label: "Trinidad and Tobago", code: "TT" },
    { label: "Tunisia", code: "TN" },
    { label: "Turkey", code: "TR" },
    { label: "Turkmenistan", code: "TM" },
    { label: "Tuvalu", code: "TV" },
    { label: "Uganda", code: "UG" },
    { label: "Ukraine", code: "UA" },
    { label: "United Arab Emirates", code: "AE" },
    { label: "United Kingdom", code: "GB" },
    { label: "United States of America", code: "US" },
    { label: "Uruguay", code: "UY" },
    { label: "Uzbekistan", code: "UZ" },
    { label: "Vanuatu", code: "VU" },
    { label: "Venezuela", code: "VE" },
    { label: "Vietnam", code: "VN" },
    { label: "Yemen", code: "YE" },
    { label: "Zambia", code: "ZM" },
    { label: "Zimbabwe", code: "ZW" }
];

export const MEASUREMENTS = [
    { label: 'Unit', code: "" },
    { label: 'Kilogram', code: "kg" },
    { label: 'Square meter', code: "m²" },
    { label: 'Liter', code: "L" },
    { label: 'Cubic meter', code: "m³" },
    { label: 'Metric ton', code: "t" }
];

export const SATELLITE_DATES = [
    {
        label: "Planet NICFI",
        items: [
            { label: "September 2020", code: "2020-09", provider: "planet" },
            { label: "October 2020", code: "2020-10", provider: "planet" },
            { label: "November 2020", code: "2020-11", provider: "planet" },
            { label: "December 2020 (EUDR)", code: "2020-12", provider: "planet" },
            { label: "January 2021", code: "2021-01", provider: "planet" },
            { label: "February 2021", code: "2021-02", provider: "planet" },
            { label: "March 2021", code: "2021-03", provider: "planet" },
            { label: "April 2021", code: "2021-04", provider: "planet" },
            { label: "May 2021", code: "2021-05", provider: "planet" },
            { label: "June 2021", code: "2021-06", provider: "planet" },
            { label: "July 2021", code: "2021-07", provider: "planet" },
            { label: "August 2021", code: "2021-08", provider: "planet" },
            { label: "September 2021", code: "2021-09", provider: "planet" },
            { label: "October 2021", code: "2021-10", provider: "planet" },
            { label: "November 2021", code: "2021-11", provider: "planet" },
            { label: "December 2021", code: "2021-12", provider: "planet" },
            { label: "January 2022", code: "2022-01", provider: "planet" },
            { label: "February 2022", code: "2022-02", provider: "planet" },
            { label: "March 2022", code: "2022-03", provider: "planet" },
            { label: "April 2022", code: "2022-04", provider: "planet" },
            { label: "May 2022", code: "2022-05", provider: "planet" },
            { label: "June 2022", code: "2022-06", provider: "planet" },
            { label: "July 2022", code: "2022-07", provider: "planet" },
            { label: "August 2022", code: "2022-08", provider: "planet" },
            { label: "September 2022", code: "2022-09", provider: "planet" },
            { label: "October 2022", code: "2022-10", provider: "planet" },
            { label: "November 2022", code: "2022-11", provider: "planet" },
            { label: "December 2022", code: "2022-12", provider: "planet" },
            { label: "January 2023", code: "2023-01", provider: "planet" },
            { label: "February 2023", code: "2023-02", provider: "planet" },
            { label: "March 2023", code: "2023-03", provider: "planet" },
            { label: "April 2023", code: "2023-04", provider: "planet" },
            { label: "May 2023", code: "2023-05", provider: "planet" },
            { label: "June 2023", code: "2023-06", provider: "planet" },
            { label: "July 2023", code: "2023-07", provider: "planet" },
            { label: "August 2023", code: "2023-08", provider: "planet" },
            { label: "September 2023", code: "2023-09", provider: "planet" },
            { label: "October 2023", code: "2023-10", provider: "planet" },
            { label: "November 2023", code: "2023-11", provider: "planet" },
            { label: "December 2023", code: "2023-12", provider: "planet" },
            { label: "January 2024", code: "2024-01", provider: "planet" },
            { label: "February 2024", code: "2024-02", provider: "planet" },
            { label: "March 2024", code: "2024-03", provider: "planet" },
            { label: "April 2024", code: "2024-04", provider: "planet" },
            { label: "May 2024", code: "2024-05", provider: "planet" },
            { label: "June 2024", code: "2024-06", provider: "planet" },
            { label: "July 2024", code: "2024-07", provider: "planet" },
            { label: "August 2024", code: "2024-08", provider: "planet" },
            { label: "September 2024", code: "2024-09", provider: "planet" },
        ]
    }
]

export const TREES = [
    { tree: "Abura", scientific: "Terminalia superba" },
    { tree: "Acacia", scientific: "Acacia spp." },
    { tree: "Acajou d'Afrique", scientific: "Khaya spp." },
    { tree: "Afrormosia", scientific: "Pericopsis elata" },
    { tree: "Ako", scientific: "Antiaris africana" },
    { tree: "Alan", scientific: "Shorea albida" },
    { tree: "Alder", scientific: "Alnus spp." },
    { tree: "Andiroba", scientific: "Carapa spp." },
    { tree: "Aningré", scientific: "Anogeissus leiocarpa" },
    { tree: "Ash", scientific: "Fraxinus spp." },
    { tree: "Aspen", scientific: "Populus spp." },
    { tree: "Avodiré", scientific: "Turraeanthus africanus" },
    { tree: "Azobé", scientific: "Lophira alata" },
    { tree: "Balau", scientific: "Shorea laevis and other Shorea spp." },
    { tree: "Balsa", scientific: "Ochroma pyramidale" },
    { tree: "Bamboo", scientific: "Bambusoideae spp." },
    { tree: "Beech", scientific: "Fagus spp." },
    { tree: "Birch", scientific: "Betula spp." },
    { tree: "Bossé clair", scientific: "Guarea cedrata / Guarea thompsonii" },
    { tree: "Bossé foncé", scientific: "Guarea spp." },
    { tree: "Cativo", scientific: "Prioria copaifera" },
    { tree: "Cedro", scientific: "Cedrela odorata" },
    { tree: "Cherry", scientific: "Prunus spp." },
    { tree: "Chestnut", scientific: "Castanea spp." },
    { tree: "Dabema", scientific: "Piptadeniastrum africanum" },
    { tree: "Dark Red Meranti", scientific: "Shorea spp." },
    { tree: "Dibétou", scientific: "Lovoa trichilioides" },
    { tree: "Doussié", scientific: "Afzelia spp." },
    { tree: "Elm", scientific: "Ulmus spp." },
    { tree: "Eucalyptus", scientific: "Eucalyptus spp." },
    { tree: "Fir", scientific: "Abies spp." },
    { tree: "Framiré", scientific: "Terminalia ivorensis" },
    { tree: "Freijo", scientific: "Cordia goeldiana" },
    { tree: "Fromager", scientific: "Ceiba pentandra" },
    { tree: "Geronggang", scientific: "Cratoxylum arborescens" },
    { tree: "Hickory", scientific: "Carya spp." },
    { tree: "Hornbeam", scientific: "Carpinus spp." },
    { tree: "Horse Chestnut", "scientific": "Aesculus hippocastanum" },
    { tree: "Ilomba", scientific: "Pycnanthus angolensis" },
    { tree: "Imbuia", scientific: "Ocotea porosa" },
    { tree: "Ipé", scientific: "Tabebuia spp." },
    { tree: "Iroko", scientific: "Milicia excelsa / Milicia regia" },
    { tree: "Jelutong", scientific: "Dyera costulata" },
    { tree: "Jequitiba", scientific: "Cariniana spp." },
    { tree: "Kapur", scientific: "Dryobalanops spp." },
    { tree: "Kempas", scientific: "Koompassia malaccensis" },
    { tree: "Keruing", scientific: "Dipterocarpus spp." },
    { tree: "Kosipo", scientific: "Entandrophragma candollei" },
    { tree: "Kotibé", scientific: "Nesogordonia papaverifera" },
    { tree: "Koto", scientific: "Pterygota spp." },
    { tree: "Light Red Meranti", scientific: "Shorea spp." },
    { tree: "Limba", scientific: "Terminalia superba" },
    { tree: "Lime", scientific: "Tilia spp." },
    { tree: "Maçaranduba", scientific: "Manilkara spp." },
    { tree: "Mahogany", scientific: "Swietenia spp." },
    { tree: "Makoré", scientific: "Tieghemella heckelii / Tieghemella africana" },
    { tree: "Mandioqueira", scientific: "Qualea spp." },
    { tree: "Mansonia", scientific: "Mansonia altissima" },
    { tree: "Maple", scientific: "Acer spp." },
    { tree: "Mengkulang", scientific: "Heritiera spp." },
    { tree: "Meranti Bakau", scientific: "Shorea spp." },
    { tree: "Merawan", scientific: "Hopea spp." },
    { tree: "Merbau", scientific: "Intsia bijuga / Intsia palembanica" },
    { tree: "Merpauh", scientific: "Sindora spp." },
    { tree: "Mersawa", scientific: "Anisoptera spp." },
    { tree: "Moabi", scientific: "Baillonella toxisperma" },
    { tree: "Niangon", scientific: "Heritiera utilis" },
    { tree: "Nyatoh", scientific: "Palaquium spp." },
    { tree: "Oak", scientific: "Quercus spp." },
    { tree: "Obeche", scientific: "Triplochiton scleroxylon" },
    { tree: "Okoumé", scientific: "Aucoumea klaineana" },
    { tree: "Ovengkol", scientific: "Guibourtia ehie" },
    { tree: "Ozigo", scientific: "Dacryodes spp." },
    { tree: "Padauk", scientific: "Pterocarpus spp." },
    { tree: "Paldao", scientific: "Dracontomelon dao" },
    { tree: "Palissandre de Guatemala", scientific: "Dalbergia spp." },
    { tree: "Palissandre de Para", scientific: "Dalbergia spp." },
    { tree: "Palissandre de Rio", scientific: "Dalbergia nigra" },
    { tree: "Palissandre de Rose", scientific: "Dalbergia spp." },
    { tree: "Pau Amarelo", scientific: "Euxylophora paraensis" },
    { tree: "Pau Marfim", scientific: "Balfourodendron riedelianum" },
    { tree: "Pine", scientific: "Pinus spp." },
    { tree: "Plane Tree", scientific: "Platanus spp." },
    { tree: "Poplar", scientific: "Populus spp." },
    { tree: "Pulai", scientific: "Alstonia spp." },
    { tree: "Punah", scientific: "Tetramerista glabra" },
    { tree: "Quaruba", scientific: "Vochysia spp." },
    { tree: "Ramin", scientific: "Gonystylus spp." },
    { tree: "Robinia", scientific: "Robinia pseudoacacia" },
    { tree: "Sapelli", scientific: "Entandrophragma cylindricum" },
    { tree: "Saqui-Saqui", scientific: "Caryocar villosum" },
    { tree: "Sepetir", scientific: "Sindora spp." },
    { tree: "Sipo", scientific: "Entandrophragma utile" },
    { tree: "Spruce", scientific: "Picea spp." },
    { tree: "Sucupira", scientific: "Bowdichia nitida" },
    { tree: "Suren", scientific: "Manilkara bidentata" },
    { tree: "Tauari", scientific: "Couratari spp." },
    { tree: "Teak", scientific: "Tectona grandis" },
    { tree: "Tiama", scientific: "Entandrophragma angolense" },
    { tree: "Tola", scientific: "Pycnanthus angolensis" },
    { tree: "Tulipwood", scientific: "Dalbergia decipularis" },
    { tree: "Virola", scientific: "Virola spp." },
    { tree: "Walnut", scientific: "Juglans spp." },
    { tree: "White Lauan", scientific: "Shorea spp." },
    { tree: "White Meranti", scientific: "Shorea spp." },
    { tree: "White Seraya", scientific: "Shorea spp." },
    { tree: "Yellow Meranti", scientific: "Shorea spp." },
    { tree: "Yellow Poplar", scientific: "Liriodendron tulipifera" },
];