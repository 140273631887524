<template>
    <Card class="h-full">
        <template #content>
            <DataTable :value="plotsStore.plots" v-model:filters="filters" v-model:selection="selectedPlot" lazy
                paginator @filter="onFilter" @page="onPage" @sort="onSort" :totalRecords="plotsStore.totalRecords"
                selectionMode="single" @rowSelect="handlePlotSelection" size="small" removableSort filterDisplay="row"
                :rows="100" :rowsPerPageOptions="[100, 250, 500]" :scrollHeight="'calc(100svh - 18rem)'" scrollable
                dataKey="_id">
                <template #empty>
                    <div v-if="!plotsStore.isTableLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <img :src="placeholder_plots" class="mb-2" width="150" />
                        <span class="font-medium mb-2">No plots found.</span>
                        <small>You can create a plot by importing data or using the drawing tool on the map.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
                <Column field="plotId" header="Plot ID" sortable :showFilterMenu="false"
                    style="white-space: nowrap; min-width: 12rem;">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            placeholder="Search" />
                    </template>
                </Column>
                <Column field="batch" header="Batch" :showFilterMenu="false" sortable style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.batch.label }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="batches"
                            optionLabel="label" placeholder="Select" />
                    </template>
                </Column>
                <Column field="geometryType" header="Geometry type" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.geometryType }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="GEOMETRY_FILTER"
                            placeholder="Select" />
                    </template>
                </Column>
                <Column field="commodity" header="Commodity" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.commodity }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="COMMODITIES"
                            placeholder="Select">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="country" header="Country" :showFilterMenu="false" sortable style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.country }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" filter :options="COUNTRIES"
                            placeholder="Select">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="area" header="Area (ha)" dataType="numeric" sortable style="white-space: nowrap;"
                    :showFilterMenu="false">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.area }}
                    </template>
                </Column>
                <Column filterField="supplier" sortField="supplier" header="Supplier" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.supplier?.name }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="suppliers"
                            optionLabel="name" placeholder="Select" showClear />
                    </template>
                </Column>
                <Column field="producerName" header="Producer name" sortable :showFilterMenu="false"
                    style="white-space: nowrap">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.producerName }}
                    </template>
                </Column>
                <Column field="additionalInfo" header="Additonal information" sortable :showFilterMenu="false"
                    style="white-space: nowrap">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.additionalInfo }}
                    </template>
                </Column>
                <Column field="treeSpecies" header="Tree species" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        <span v-if="data.treeSpecies">{{ data.treeSpecies?.tree }}
                            ({{ data.treeSpecies?.scientific }})</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback()" placeholder="Search" />
                    </template>
                </Column>
                <Column header="" alignFrozen="right" frozen>
                    <template #body="{ data }">
                        <Button icon="pi pi-pencil" outlined size="small" class="mr-2"
                            @click="plotsStore.showEditDialog(data)" />
                    </template>
                </Column>
                <template #footer>
                    <div class="flex">
                        <div class="flex align-items-center">
                            <span class="text-base font-normal">Total plots:{{ plotsStore.totalRecords }}</span>
                        </div>
                    </div>
                </template>
            </DataTable>
        </template>
    </Card>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { usePlotsStore } from '../../stores/plots';
import { useSupplierStore } from "../../stores/supplier";
import { useToast } from 'primevue/usetoast';
import { COMMODITIES, COUNTRIES, GEOMETRY_FILTER } from "../../utils/constants"
import * as Sentry from "@sentry/vue";
import * as turf from '@turf/turf';
import { FilterMatchMode } from 'primevue/api';
import { useBatchStore } from '../../stores/batch';
import debounce from 'lodash.debounce';
import placeholder_plots from "../../assets/placeholder_plots.svg"


const { getPaginationPlots } = useAnalyticsAPI();
const batchStore = useBatchStore();
const batches = ref();
const toast = useToast();
const supplierStore = useSupplierStore();
const plotsStore = usePlotsStore();
const selectedPlot = ref();
const suppliers = ref([]);
const filters = ref({
    plotId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    batch: { value: null, matchMode: FilterMatchMode.IN },
    country: { value: null, matchMode: FilterMatchMode.EQUALS },
    area: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    geometryType: { value: null, matchMode: FilterMatchMode.IN },
    commodity: { value: null, matchMode: FilterMatchMode.EQUALS },
    additionalInfo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    producerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    supplier: { value: null, matchMode: FilterMatchMode.IN },
    treeSpecies: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const map = inject('map');


function flyToPlotAndHighlightIt(feature) {
    const type = feature.geometry.type;

    if (type === 'Point') {
        map.value.flyTo({
            center: feature.geometry.coordinates,
            zoom: 18,
            duration: 2000,
            essential: true
        });
    }
    else {
        const bbox = turf.bbox(feature.geometry);
        map.value.fitBounds(bbox, {
            padding: { top: 100, bottom: 100, left: 100, right: 100 },
            maxZoom: 16,
            duration: 2000,
            essential: true
        });
    }
}

function onPage(event) {
    if (!plotsStore.selectedPages[event.page]) {
        plotsStore.selectedPages[event.page] = {
            selected: false,
            plots: []
        }
    }
    plotsStore.isCurrentPageSelected = plotsStore.selectedPages[event.page].selected;
    plotsStore.pageParams = event;
    loadPageData(event);
}

function onSort(event) {
    plotsStore.pageParams = event;
    loadPageData(event);
}

// Create a debounced version of your loadPageData function
const debouncedLoadPageData = debounce((event) => {
    loadPageData(event);
}, 500);

// Modify your onFilter function to use the debounced load
function onFilter(event) {
    console.log(event);
    plotsStore.selectedPages = [];
    plotsStore.pageParams.filters = event.filters;
    debouncedLoadPageData(event);
}

async function loadPageData(event) {
    plotsStore.isTableLoading = true;
    const offset = event.first;
    const limit = plotsStore.pageParams.rows;
    const filters = plotsStore.pageParams.filters;
    const processedFilters = {}

    for (const [field, filterConfig] of Object.entries(filters)) {
        if (filterConfig.value !== null) {
            if (field === "batch") {
                const batchField = []
                for (const value of filterConfig.value) {
                    batchField.push(value._id)
                }
                processedFilters[field] = batchField
            } else if (field === "supplier") {
                const supplier = [];
                for (const value of filterConfig.value) {
                    supplier.push(value._id)
                }
                processedFilters[field] = supplier;
            }
            else {
                processedFilters[field] = filterConfig.value;
            }
        }
    }

    const body = {
        limit: limit,
        offset: offset,
        filters: JSON.stringify(processedFilters),
        sortField: event.sortField,
        sortOrder: event.sortOrder,
    }

    console.log("calling backend", body)

    try {
        const response = await getPaginationPlots(body);
        console.log(response)
        plotsStore.plots = response.plots;
        plotsStore.totalRecords = response.totalRecords;
        plotsStore.isTableLoading = false;
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.add({ group: 'headless', severity: 'error', summary: 'Error', detail: 'Failed to fetch plots.', life: 3000 });
    } finally {
        plotsStore.isTableLoading = false;
    }
}

function handlePlotSelection(event) {
    const selectedPlot = plotsStore.plots.filter(plot => plot._id === event.data._id)[0];
    plotsStore.selectedPlotOnMap = selectedPlot;
    const properties = {
        featureId: selectedPlot._id
    }
    selectedPlot.properties = properties;// just for adapting to flyToPlotAndHighlightIt for testing
    flyToPlotAndHighlightIt(selectedPlot);
}

async function fetchPlots() {
    plotsStore.isTableLoading = true;
    plotsStore.pageParams = {
        first: 0,
        rows: 100,  // Default rows per page
        sortField: null,
        sortOrder: null,
        page: 0,
        filters: {}
    };

    await loadPageData(plotsStore.pageParams);
    await supplierStore.fetchAllSuppliers();
    suppliers.value = supplierStore.allSuppliers.map(supplier => ({ _id: supplier._id, name: supplier.name }));
    console.log(batchStore.allBatches)
    batches.value = batchStore.allBatches.map(batch => ({ _id: batch._id, label: batch.label }));
};


fetchPlots();
</script>