<template>
    <div v-if="isLegendVisible" class="p-card p-2" style="width: 260px;">
        <div class="flex justify-content-between align-items-center pb-1">
            <span class="text-base font-bold">Legend</span>
            <i class="pi pi-times-circle cursor-pointer" @click="closeLegend"></i>
        </div>

        <!-- Dynamically Render Sections -->
        <div v-for="(layerGroup, sectionKey) in layers" :key="sectionKey" class="p-2 rounded-border group-border mt-2">
            <!-- Section Header -->
            <div class="flex align-items-center justify-content-between cursor-pointer"
                @click="toggleSectionVisibility(sectionKey)">
                <span class="font-medium mb-1">{{ layerGroup.title }}</span>
                <i :class="isSectionVisible[sectionKey] ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
            </div>

            <!-- Section Content (Visible when opened) -->
            <div v-if="isSectionVisible[sectionKey]">
                <div v-for="layer in layerGroup.items" :key="layer.id"
                    class="flex align-items-center justify-content-between">
                    <div class="flex align-items-center gap-2">
                        <span class="color-box" :style="{ backgroundColor: layer.color }"></span>
                        <span>{{ layer.name }}</span>
                    </div>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                            v-tooltip.bottom="{ value: layer.tooltip, autoHide: false }"></i>
                        <i :class="isLayerVisible[layer.id] ? 'pi pi-eye' : 'pi pi-eye-slash'" class="cursor-pointer"
                            @click="toggleVisibility(layer.id)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="p-card p-2 cursor-pointer" @click="openLegend">
        <img :src="layerIcon" width="20px">
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import layerIcon from "../../../assets/icons/layer.png"


let batchMap = inject("batchMap");
const isLegendVisible = ref(true);
const tooltipEuForest = "The map on global forest cover (GFC 2020) by the Joint Research Centre was created by combining available global datasets on tree cover, tree height, land cover and land use into a single harmonized globally-consistent representation of where forests existed in 2020. Data source: \n https://forest-observatory.ec.europa.eu/forest \n https://forobs.jrc.ec.europa.eu/GFC"
const tooltipJrcDeforestation = "The Tropical Moist Forest dataset from the Joint Research Centre provides information on changes in forest cover from 1990 up to the year 2023 in the humid tropics. The wall-to-wall maps at 0.09 ha resolution (30m) depict the tropical moist forest extent and the related disturbances (deforestation and degradation). Deforestation refers to a change in land cover (from forest to non-forested land) when degradation refers to a short-duration disturbance in a forest remaining forested such as selective logging, fires and unusual weather events (hurricanes, droughts, blowdown). Data source: https://forest-observatory.ec.europa.eu/forest"
const tooltipNadarForest = "Nadar’s proprietary forest cover 2020 map is based on remote sensing and Machine Learning analysis using time series satellite imagery from the Copernicus Sentinel-2 mission of the European Space Agency (ESA) at a spatial resolution of 10 meters. Data source: https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2 Nadar’s forest map follows the forest definition of the Regulation (EU) 2023/1115 Article 2 (4)."
const tooltipNadarDeforestation = "Nadar’s proprietary deforestation map is based on remote sensing and Machine Learning analysis using time series satellite imagery from the Copernicus Sentinel-2 mission of the European Space Agency (ESA) at a spatial resolution of 10 meters. Data source: https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2 Nadar’s deforestation map follows the definition of the Regulation (EU) 2023/1115 Article 2 (3)."
const tooltipProtectedAreas = "Nadar’s dataset on protected areas combines geospatial data from Open Street Map (OSM) and various national databases on protected areas; protected or conserved areas implemented for biodiversity conservation such as national parks, wilderness areas, community conserved areas, and nature reserves. Data sources: https://www.openstreetmap.org"
const tooltipIndigenousLands = "Nadar’s dataset on indigenous lands combines geospatial data from Open Street Map (OSM), LandMark, RAISG and national databases on indigenous lands. Data sources:  https://www.openstreetmap.org https://www.raisg.org/en/"
const tooltipNadarLandcover = "Nadar’s proprietary landcover map is based on remote sensing and Machine Learning analysis using time series satellite imagery from the Copernicus Sentinel-2 mission of the European Space Agency (ESA) at a spatial resolution of 10 meters. Data source: https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2 Nadar’s deforestation map follows the definition of the Regulation (EU) 2023/1115 Article 2 (3)."
const isSectionVisible = ref({
    publicMaps: false,
    nadarMaps: false,
    nadarLandcover: false,
    legality: false
});
const isLayerVisible = ref({
    'eu-forest-layer': false,
    'eu-deforestation-2021-layer': false,
    'eu-deforestation-2022-layer': false,
    'eu-deforestation-2023-layer': false,
    'nadar-forest-layer': false,
    'nadar-deforestation-2021-layer': false,
    'nadar-deforestation-2022-layer': false,
    'nadar-deforestation-2023-layer': false,
    'nadar-agriculture-layer': false,
    'nadar-monoculture-layer': false,
    'nadar-agroforestry-layer': false,
    'nadar-other-layer': false,
    'nadar-water-layer': false,
    'nadar-urban-layer': false,
    'protected-areas-layer': false,
    'indigenous-lands-layer': false,
});

// Centralized Layers Data
const layers = {
    publicMaps: {
        title: "Public Maps",
        items: [
            { id: "eu-forest-layer", name: "EU Forest 2020 (GFC)", color: "#029C54", tooltip: tooltipEuForest },
            { id: "eu-deforestation-2021-layer", name: "JRC Deforestation 2021", color: "#DB0505", tooltip: tooltipJrcDeforestation },
            { id: "eu-deforestation-2022-layer", name: "JRC Deforestation 2022", color: "#DB0505", tooltip: tooltipJrcDeforestation },
            { id: "eu-deforestation-2023-layer", name: "JRC Deforestation 2023", color: "#DB0505", tooltip: tooltipJrcDeforestation },
        ]
    },
    nadarMaps: {
        title: "Nadar Maps",
        items: [
            { id: "nadar-forest-layer", name: "Nadar Forest 2020", color: "#62FF74", tooltip: tooltipNadarForest },
            { id: "nadar-deforestation-2021-layer", name: "Nadar Deforestation 2021", color: "#FF4400", tooltip: tooltipNadarDeforestation },
            { id: "nadar-deforestation-2022-layer", name: "Nadar Deforestation 2022", color: "#FF4400", tooltip: tooltipNadarDeforestation },
            { id: "nadar-deforestation-2023-layer", name: "Nadar Deforestation 2023", color: "#FF4400", tooltip: tooltipNadarDeforestation },
        ]
    },
    nadarLandcover: {
        title: "Nadar Land Cover 2020",
        items: [
            { id: "nadar-agriculture-layer", name: "Agriculture", color: "#FFFF00FF", tooltip: tooltipNadarLandcover },
            { id: "nadar-monoculture-layer", name: "Monoculture", color: "#8B4513FF", tooltip: tooltipNadarLandcover },
            { id: "nadar-agroforestry-layer", name: "Agroforestry", color: "#FFA500FF", tooltip: tooltipNadarLandcover },
            { id: "nadar-other-layer", name: "Other Vegetation", color: "#C0B283FF", tooltip: tooltipNadarLandcover },
            { id: "nadar-water-layer", name: "Water", color: "#0000FFFF", tooltip: tooltipNadarLandcover },
            { id: "nadar-urban-layer", name: "Urban", color: "#FF00FF", tooltip: tooltipNadarLandcover },
        ]
    },
    legality: {
        title: "Legality",
        items: [
            { id: "protected-areas-layer", name: "Protected Areas", color: "#7768FF", tooltip: tooltipProtectedAreas },
            { id: "indigenous-lands-layer", name: "Indigenous Lands", color: "#FFCE74", tooltip: tooltipIndigenousLands },
        ]
    }
};

function openLegend() {
    isLegendVisible.value = true;
}

function closeLegend() {
    isLegendVisible.value = false;
}

function toggleSectionVisibility(section) {
    isSectionVisible.value[section] = !isSectionVisible.value[section];
}

function toggleVisibility(layer) {
    isLayerVisible.value[layer] = !isLayerVisible.value[layer];
    const visibility = isLayerVisible.value[layer] ? "visible" : "none";
    batchMap.value.setLayoutProperty(layer, 'visibility', visibility);

    if (layer === "protected-areas-layer") {
        batchMap.value.setLayoutProperty("protected-areas-filled-layer", 'visibility', visibility);
    }
    if (layer === "indigenous-lands-layer") {
        batchMap.value.setLayoutProperty("indigenous-lands-filled-layer", 'visibility', visibility);
    }
}
</script>



<style scoped>
.color-box {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.bg-eu-forest {
    background-color: #029C54;
}

.bg-jrc-red {
    background-color: #DB0505;
}

.bg-nadar-forest {
    background-color: #62FF74;
}

.bg-nadar-deforestation {
    background-color: #FF4400;
}

.bg-nadar-agriculture {
    background-color: #FFFF00FF;
}

.bg-nadar-monoculture {
    background-color: #8B4513FF;
}

.bg-nadar-agroforestry {
    background-color: #FFA500FF;
}

.bg-nadar-other {
    background-color: #C0B283FF;
}

.bg-nadar-water {
    background-color: #0000FFFF;
}

.bg-nadar-urban {
    background-color: #FF00FF;
}

.border-protected {
    border: 2px solid #7768FF;
    background-color: transparent;
}

.border-indigenous {
    border: 2px solid #FFCE74;
    background-color: transparent;
}

.group-border {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}
</style>