<template>
    <div class="h-screen p-3">
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
                <span class="font-medium text-2xl">Plots</span>
                <div class="relative">
                    <i class="pi pi-info-circle cursor-pointer" @mouseenter="showTooltip = true"
                        @mouseleave="showTooltip = false"></i>
                    <div v-show="showTooltip" class="absolute surface-0 border-round shadow-2 p-3 w-30rem"
                        style="top: 100%; left: 0; margin-top: 0.5rem; z-index: 1000">
                        View existing plots or create new ones. Add plots by drawing points or polygons directly
                        on the map, or import them from Excel, CSV, KML, KMZ, GeoJSON, or SHP files.
                    </div>
                </div>
            </div>
            <div>
                <Button label="Import plots" class="mr-2" @click="dataImportStore.showDataImportDialog()"
                    :loading="plotsStore.isTableLoading" />
                <RefreshButton />
            </div>
        </div>

        <div class="grid mt-3" style="height: calc(100% - 3rem)">
            <div class="col-6 h-full">
                <div class="h-full">
                    <PlotsTable />
                </div>
            </div>

            <div class="col-6 h-full">
                <div class="h-full">
                    <PlotsMap />
                </div>
            </div>
        </div>
    </div>

    <DataImportStepper />
    <DataImportSuccessDialog />
    <DataImportFailureDialog />
    <InvalidFileDialog />
    <InvalidGeometryDialog />
    <FileProcessingDialog />
    <InvalidAreaCellDialog />
    <CreatePlotDialog />
    <EditPlotDialog />
</template>

<script setup>
import { ref, provide, onUnmounted } from "vue";
import { useBatchStore } from "../stores/batch";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import PlotsTable from "../components/plots/PlotsTable.vue";
import PlotsMap from "../components/plots/PlotsMap.vue";
import { usePlotsStore } from "../stores/plots";
import DataImportSuccessDialog from "../components/import/DataImportSuccessDialog.vue";
import DataImportStepper from "../components/import/DataImportStepper.vue";
import { useDataImportStore } from "../stores/dataImport";
import DataImportFailureDialog from "../components/import/DataImportFailureDialog.vue";
import InvalidFileDialog from "../components/import/InvalidFileDialog.vue";
import InvalidGeometryDialog from "../components/import/InvalidGeometryDialog.vue";
import FileProcessingDialog from "../components/import/FileProcessingDialog.vue";
import InvalidAreaCellDialog from "../components/import/InvalidAreaCellDialog.vue";
import RefreshButton from "../components/plots/RefreshButton.vue";
import CreatePlotDialog from "../components/plots/CreatePlotDialog.vue";
import { useSupplierStore } from "../stores/supplier";
import EditPlotDialog from "../components/plots/EditPlotDialog.vue";



const dataImportStore = useDataImportStore();
const batchStore = useBatchStore();
const plotsStore = usePlotsStore();
const supplierStore = useSupplierStore();
const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
        polygon: false,
        trash: false,
    }
});
let map = ref();
const showTooltip = ref(false);


provide("draw", draw);
provide("map", map);

batchStore.fetchAllBatches();
supplierStore.fetchAllSuppliers();

onUnmounted(() => {
    plotsStore.$reset()
    dataImportStore.$reset();

})
</script>

<style scoped>
.tooltip-container {
    position: relative;
}
</style>