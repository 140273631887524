<template>
    <div class="grid">
        <div class="col-3">
            <Card :pt="{ title: 'text-base' }">
                <template #title class="font-medium text-xs">Total batches</template>
                <template #content>
                    <span class="text-3xl font-medium">{{ batchCount }}</span>
                </template>
            </Card>
        </div>
        <div class="col-3">
            <Card :pt="{ title: 'text-base' }">
                <template #title class="font-medium text-xs">Total plots</template>
                <template #content>
                    <span class="text-3xl font-medium">{{ plotCount }}</span>
                </template>
            </Card>
        </div>
        <div class="col-3">
            <Card :pt="{ title: 'text-base' }">
                <template #title class="font-medium text-xs">Total area (ha)</template>
                <template #content>
                    <span class="text-3xl font-medium">{{ totalAreaHectares }}</span>
                </template>
            </Card>
        </div>
        <div class="col-3">
            <Card :pt="{ title: 'text-base' }">
                <template #title class="font-medium text-xs">Total countries</template>
                <template #content>
                    <span class="text-3xl font-medium">{{ uniqueCountryCount }}</span>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useBatchStore } from '../../stores/batch';


const batchStore = useBatchStore();
const batchCount = ref(0);
const plotCount = ref(0);
const totalAreaHectares = ref(0);
const uniqueCountryCount = ref(0);


function updateDashboardMetrics() {
    batchCount.value = batchStore.allBatches.length;
    const uniqueCountries = new Set();
    plotCount.value = 0;
    let totalArea = 0;

    for (const batch of batchStore.allBatches) {
        totalArea += batch.areaCount;
        plotCount.value += batch.plotCount;
        batch.countries.forEach(country => uniqueCountries.add(country));
    }

    totalAreaHectares.value = Number(totalArea.toFixed(2));
    uniqueCountryCount.value = uniqueCountries.size;
}

// Initialize dashboard metrics
onMounted(() => {
    updateDashboardMetrics();
});

// Update metrics when batches change
watch(() => batchStore.allBatches, () => {
    updateDashboardMetrics();
}, { deep: true });
</script>