<template>
    <div class="grid">
        <div class="col-8 col-offset-2">
            <Card>
                <template #content>
                    <div class="flex justify-content-between align-items-center">
                        <span class="text-lg font-medium">TRACES info</span>
                        <div>
                            <Button label="Import Excel" @click="triggerFileSelection" />
                            <!-- Hidden file input -->
                            <input ref="fileInput" type="file" accept=".xlsx" @change="handleFileSelection"
                                style="display: none;" />
                        </div>
                    </div>
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex flex-column flex-grow-1">
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Internal reference number</label>
                                <InputText v-model="internalReferenceNumber" />
                                <small>Excel column "internalReferenceNumber"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">HS code</label>
                                <div class="flex">
                                    <InputText v-model="selectedHsCode" class="w-full mr-1" readonly />
                                    <Button label="Select" @click="showHsCodeSelector" outlined />
                                </div>
                                <small>Excel column "hsCode"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Product description</label>
                                <InputText v-model="descriptionOfGoods" />
                                <small>Excel column "descriptionOfGoods"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Net Mass</label>
                                <InputText v-model="netWeight" />
                                <small>The net weight is in Kg. Excel column "netWeight"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Volume</label>
                                <InputText v-model="volume" />
                                <small>The volume is in m³. Excel column "volume"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Supplementary Units</label>
                                <div class="flex">
                                    <InputText v-model="numberOfUnits" class="w-full mr-1" />
                                    <Dropdown v-model="universalUnit" :options="UNITS" optionLabel="label"
                                        placeholder="Universal unit" showClear>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.label }} ({{ slotProps.option.code }})</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </div>
                                <small>Excel columns "numberOfUnits" and "universalUnit"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Scientific name</label>
                                <InputText v-model="scientificName" />
                                <small>Excel column "scientificName"</small>
                            </div>
                            <div class="flex flex-column gap-2 mt-3">
                                <label class="font-medium">Common name</label>
                                <InputText v-model="commonName" />
                                <small>Excel column "commonName"</small>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-content-end mt-3">
                        <Button label="Save" class="light-green-nadar" @click="handleSaveBatchDetails"
                            :loading="isBatchDetailsSaving" />
                    </div>
                </template>
            </Card>
        </div>
    </div>

    <Dialog v-model:visible="isSelectHsCodeDialogShown" modal header="Select HS Code" :style="{ width: '60rem' }">
        <DataTable :value="HS_CODES" dataKey="chapter" v-model:expandedRows="expandedRows" scrollable
            scrollHeight="60vh">
            <Column expander style="width: 5rem" />
            <Column field="chapter" />
            <template #expansion="slotProps">
                <div class="p-3">
                    <h5>{{ slotProps.data.chapter }}</h5>
                    <DataTable v-model:selection="selectedHsCodeDetails" :value="slotProps.data.commodities">
                        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
                        <Column field="title" header="HS Code" />
                        <Column field="description" header="Description" />
                    </DataTable>
                </div>
            </template>
        </DataTable>
        <div class="flex justify-content-end mt-3">
            <Button label="Select" :disabled="!selectedHsCodeDetails" @click="addHsCode" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { HS_CODES, UNITS } from '../../utils/constants';
import * as XLSX from 'xlsx';
import { parse } from 'papaparse';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const { getBatchByBatchObjectId, patchBatch, getFilesOfBatch } = useAnalyticsAPI();
const route = useRoute();
const toast = useToast();
const batch = ref();
const geodataFile = ref();
const internalReferenceNumber = ref();
const descriptionOfGoods = ref();
const netWeight = ref();
const volume = ref();
const numberOfUnits = ref(null);
const universalUnit = ref(null);
const scientificName = ref();
const commonName = ref();
const selectedHsCodeDetails = ref([]);
const selectedHsCode = ref();
const expandedRows = ref(null);
const isSelectHsCodeDialogShown = ref(false);
const selectedFile = ref(null);
const fileInput = ref(null);
const parsedData = ref();
const columnNames = ref();
const isBatchDetailsSaving = ref(false);


function triggerFileSelection() {
    fileInput.value.click();
}

function insertDataFromXLSX() {
    const data = parsedData.value?.data?.[0];

    if (data) {
        commonName.value = data.commonName;
        descriptionOfGoods.value = data.descriptionOfGoods;
        selectedHsCode.value = data.hsCode;
        internalReferenceNumber.value = data.internalReferenceNumber;
        netWeight.value = data.netWeight;
        numberOfUnits.value = data.numberOfUnits;
        scientificName.value = data.scientificName;
        universalUnit.value = UNITS.find(option => option.value === data.universalUnit);
        volume.value = data.volume;
    } else {
        toast.add({ group: "headless", severity: 'error', summary: 'No data', detail: "No data found in XLSX file." });
    }
}

async function handleFileSelection(event) {
    const file = event.target.files[0];
    if (file) {
        selectedFile.value = file;
        await parseXLSX(file);
        insertDataFromXLSX();
    }
}

function detectDelimiter(csvText) {
    const delimiters = [',', ';', '\t'];
    const firstLine = csvText.split('\n')[0]; // Check the first line (header)
    const delimiterCounts = {};

    delimiters.forEach(delimiter => {
        delimiterCounts[delimiter] = (firstLine.split(delimiter).length - 1);
    });

    return Object.keys(delimiterCounts).reduce((a, b) => delimiterCounts[a] > delimiterCounts[b] ? a : b);
}

async function parseXLSX(file) {
    const arrayBuffer = await file.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const csvText = XLSX.utils.sheet_to_csv(worksheet);
    const delimiter = detectDelimiter(csvText);
    parsedData.value = parse(csvText, { header: true, delimiter: delimiter, dynamicTyping: false });
    columnNames.value = parsedData.value.meta.fields;
}

function addHsCode() {
    selectedHsCode.value = selectedHsCodeDetails.value.code;
    isSelectHsCodeDialogShown.value = false;
}

function showHsCodeSelector() {
    isSelectHsCodeDialogShown.value = true;
}

function checkRequiredFields() {
    // Check if numberOfUnits is a positive integer
    if (numberOfUnits.value)
        if (
            isNaN(parseInt(numberOfUnits.value, 10)) ||
            !Number.isInteger(Number(numberOfUnits.value)) ||
            Number(numberOfUnits.value) <= 0 // Ensure it is positive)
        ) {
            toast.add({ group: "headless", severity: 'error', summary: 'Invalid Number of Units', detail: "Number of Units must be a positive integer.", life: 3000 });
            return false;
        }

    // Check if netWeight is a positive float
    if (netWeight.value)
        if (
            isNaN(parseFloat(netWeight.value)) || // Check if it is not a valid number
            !/^\d+(\.\d+)?$/.test(netWeight.value) || // Ensure it contains only numeric characters and at most one decimal point
            Number(netWeight.value) <= 0 // Ensure it is positive
        ) {
            toast.add({ group: "headless", severity: 'error', summary: 'Invalid Net Weight', detail: "Net Weight must be a positive numeric value.", life: 3000 });
            return false;
        }

    // Check if volume is a positive float
    if (volume.value)
        if (
            isNaN(parseFloat(volume.value)) || // Check if it is not a valid number
            !/^\d+(\.\d+)?$/.test(volume.value) || // Ensure it contains only numeric characters and at most one decimal point
            Number(volume.value) <= 0 // Ensure it is positive
        ) {
            toast.add({ group: "headless", severity: 'error', summary: 'Invalid Volume', detail: "Volume must be a positive numeric value.", life: 3000 });
            return false;
        }

    // Check if universalUnit and numberOfUnits are both filled or both empty
    if ((universalUnit.value && !numberOfUnits.value) || (!universalUnit.value && numberOfUnits.value)) {
        toast.add({
            group: "headless",
            severity: 'error',
            summary: 'Missing Fields',
            detail: "Both 'Universal Unit' and 'Number of Units' must be filled or both left empty.",
            life: 3000
        });
        return false;
    }

    return true; // All checks passed
}

async function handleSaveBatchDetails() {
    if (!checkRequiredFields()) {
        return;
    }

    isBatchDetailsSaving.value = true;

    const body = {
        "internalReferenceNumber": internalReferenceNumber.value?.trim(),
        "hsHeading": selectedHsCode.value.trim(),
        "descriptionOfGoods": descriptionOfGoods.value?.trim(),
        "netWeight": netWeight.value ? Number(netWeight.value) : null,
        "volume": volume.value ? Number(volume.value) : null,
        "numberOfUnits": numberOfUnits.value ? Number(numberOfUnits.value) : null,
        "universalUnit": universalUnit.value ? universalUnit.value.code : null,
        "scientificName": scientificName.value?.trim(),
        "commonName": commonName.value?.trim()
    };

    try {
        console.log(batch.value);
        const response = await patchBatch(batch.value._id, body);
        toast.add({ group: 'headless', severity: 'success', summary: 'Success', detail: 'Successfully updated TRACES info.', life: 3000 });
    } catch (error) {
        Sentry.captureException(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: "Failed to update TRACES info.", life: 3000 });
        console.log(error);
    } finally {
        isBatchDetailsSaving.value = false;
    }
}

async function fetchBatch() {
    const batchObjectId = route.params.batchObjectId;
    try {
        batch.value = await getBatchByBatchObjectId(batchObjectId);
        internalReferenceNumber.value = batch.value.internalReferenceNumber || '';
        selectedHsCode.value = batch.value.hsHeading || '';
        descriptionOfGoods.value = batch.value.descriptionOfGoods || '';
        netWeight.value = batch.value.netWeight || '';
        volume.value = batch.value.volume || '';
        numberOfUnits.value = batch.value.numberOfUnits || null;
        universalUnit.value = UNITS.find(option => option.value = batch.value.universalUnit) || null;
        scientificName.value = batch.value.scientificName || '';
        commonName.value = batch.value.commonName || '';
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
    }
}

async function fetchFiles() {
    const batchObjectId = route.params.batchObjectId;
    try {
        const response = await getFilesOfBatch(batchObjectId);
        console.log(response)
        geodataFile.value = response[0];
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
    }
}


fetchBatch();
fetchFiles();
</script>