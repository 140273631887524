import { defineStore } from 'pinia'
import { useIdentityService } from '../composables/useIdentityService';

export const useUsersStore = defineStore('users', {
    state: () => ({
        currentUser: {},
        isCurrentUserLoading: false,
        allUsers: [],
        isAllUsersLoading: false,
    }),
    actions: {
        async fetchCurrentUser() {
            const { getCurrentUser } = useIdentityService();
            this.isCurrentUserLoading = true;

            try {
                const currentUser = await getCurrentUser();
                this.currentUser = currentUser;
            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.isCurrentUserLoading = false;
            }
        },
        async fetchAllUsers() {
            const { getAllUsers } = useIdentityService();
            this.isAllUsersLoading = true;
            this.allUsers = [];

            try {
                const allUsers = await getAllUsers();
                this.allUsers = allUsers;
            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.isAllUsersLoading = false;
            }
        },
    },
    getters: {
        hasPermission: (state) => (permission) => {
            return state.currentUser.permissions.includes(permission);
        },
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'user',
                storage: localStorage,
            },
        ],
    },
});