import { inject } from 'vue';
import * as turf from '@turf/turf';
import * as Sentry from "@sentry/vue";
import { usePagination } from './usePagination';
import { useAnalyticsAPI } from '../../../../composables/useAnalyticsAPI';
import { useRoute } from 'vue-router';
import { usePlotStore } from '../../../../stores/plot.js';


export function usePlotRiskUtils() {
    let batchMap = inject("batchMap");
    const plotStore = usePlotStore();
    const { getPaginationBatchPlots } = useAnalyticsAPI();
    const { unselectAllCheckboxes } = usePagination();
    const VITE_TILES_SERVICE_EXPRESS_URL = import.meta.env.VITE_TILES_SERVICE_EXPRESS_URL;
    const route = useRoute();


    function flyToPlotAndHighlightIt(feature) {
        const type = feature.geometry.type;

        if (type === 'Point') {
            //changePlotColor(feature.properties.featureId, "Point");
            batchMap.value.flyTo({
                center: feature.geometry.coordinates,
                zoom: 16,
                duration: 2000,
                essential: true
            });
        }
        else {
            //changePlotColor(feature.properties.featureId, "Polygon"); //Same for LineString
            const bbox = turf.bbox(feature.geometry);
            batchMap.value.fitBounds(bbox, {
                padding: { top: 100, bottom: 100, left: 100, right: 100 },
                maxZoom: 16,
                duration: 2000,
                essential: true
            });
        }
    }

    async function refreshData() {
        unselectAllCheckboxes();
        plotStore.resetFilters();
        plotStore.isTableLoading = true;
        plotStore.plots = [];
        const limit = plotStore.pageParams.rows;
        const offset = 0;
        const params = {
            limit: limit,
            offset: offset,
            batchObjectId: route.params.batchObjectId,
        }

        try {
            const response = await getPaginationBatchPlots(params);
            console.log(response)
            plotStore.plots = response.plots;
            plotStore.totalRecords = response.totalRecords;
            const batchObjectId = route.params.batchObjectId;
            if (batchMap.value && batchMap.value.getSource('plots-source')) {
                batchMap.value.getSource('plots-source').setTiles([`${VITE_TILES_SERVICE_EXPRESS_URL}/plots/batch/${batchObjectId}/{z}/{x}/{y}.pbf`]);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
        } finally {
            plotStore.isTableLoading = false;
        }
    }

    return {
        flyToPlotAndHighlightIt,
        refreshData
    }
}
