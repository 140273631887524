import axios from "axios";
import { useDialogStore } from "../stores/dialog"


export function useIdentityService() {
    const VITE_IDENTITY_SERVICE_URL = import.meta.env.VITE_IDENTITY_SERVICE_URL;
    const dialogStore = useDialogStore();

    // Create base Axios instance for general API calls
    const axiosInstance = axios.create({
        baseURL: VITE_IDENTITY_SERVICE_URL,
    });

    const axiosInstanceWithoutSessionExpired = axios.create({
        baseURL: VITE_IDENTITY_SERVICE_URL,
    });

    axiosInstanceWithoutSessionExpired.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                dialogStore.isSessionExpired = true;
            }
        }
        return Promise.reject(error);
    });


    async function getImpersonateToken(adminUid, userUid) {
        const response = await axiosInstance.get(`/auth/generate/${adminUid}/${userUid}`);
        return response.data
    }

    async function getCurrentUser() {
        const response = await axiosInstance.get(`/users/me`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllUsers() {
        const response = await axiosInstance.get(`/users`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getCurrentOrganization() {
        const response = await axiosInstance.get(`/organization`, {
            withCredentials: true,
        });
        return response.data
    }

    //POST

    async function postUser(body) {
        const response = await axiosInstance.post(`/users`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postLoginUser(idToken) {
        const response = await axiosInstance.post(`/auth/login`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLoginAdmin(idToken) {
        const response = await axiosInstance.post(`/auth/login-admin`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLogoutUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/logout`, {}, {
            withCredentials: true,
        });
        return response.data
    }

    async function postVerifyUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/verify`, {}, {
            withCredentials: true,
        });
        return response
    }

    // PATCH

    async function patchCurrentUser(body) {
        const response = await axiosInstance.patch(`/users/me`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchUser(uoid, body) {
        const response = await axiosInstance.patch(`/users/${uoid}`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchCurrentOrganization(body) {
        const response = await axiosInstance.patch(`/organization`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function deleteUser(uoid) {
        const response = await axiosInstance.delete(`/users/${uoid}`, {
            withCredentials: true,
        });
        return response.data
    }

    return {
        getImpersonateToken,
        getCurrentUser,
        getAllUsers,
        getCurrentOrganization,
        postLoginUser,
        postUser,
        postLoginAdmin,
        postLogoutUser,
        postVerifyUser,
        patchCurrentOrganization,
        patchCurrentUser,
        patchUser,
        deleteUser
    }
}
