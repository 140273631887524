import { defineStore } from 'pinia'
import { useAnalyticsAPI } from '../composables/useAnalyticsAPI';
import { cloneDeep } from 'lodash';

export const useSupplierStore = defineStore('supplier', {
    state: () => ({
        allSuppliers: [],
        isTableLoading: false,
        supplierToEdit: null,
        supplierToDelete: null,
        isCreateDialogShown: false,
        isEditDialogShown: false,
        isDeleteDialogShown: false
    }),
    actions: {
        async fetchAllSuppliers() {
            const { getAllSuppliers } = useAnalyticsAPI();
            this.isTableLoading = true;
            this.allSuppliers = [];
            try {
                const suppliers = await getAllSuppliers();
                this.allSuppliers = suppliers;
                console.log(this.allSuppliers)
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                this.isTableLoading = false;
            }
        },
        showCreateDialog() {
            this.isCreateDialogShown = true;
        },
        hideCreateDialog() {
            this.isCreateDialogShown = false;
        },
        showEditDialog(supplier) {
            this.supplierToEdit = cloneDeep(supplier);
            this.isEditDialogShown = true;
        },
        hideEditDialog() {
            this.supplierToEdit = null;
            this.isEditDialogShown = false;
        },
        showDeleteDialog(supplier) {
            this.supplierToDelete = cloneDeep(supplier);;
            this.isDeleteDialogShown = true;
        },
        hideDeleteDialog() {
            this.supplierToDelete = null;
            this.isDeleteDialogShown = false;
        },
    }
});