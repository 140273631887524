import { defineStore } from 'pinia'
import { FilterMatchMode } from 'primevue/api';
import { GEOMETRY_MODE } from '../utils/constants';
import { cloneDeep } from 'lodash';

const filters = {
    plotId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    country: { value: null, matchMode: FilterMatchMode.EQUALS },
    area: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    dataQuality: { value: null, matchMode: FilterMatchMode.IN },
    deforestationNadar: { value: null, matchMode: FilterMatchMode.IN },
    deforestationJrc: { value: null, matchMode: FilterMatchMode.IN },
    geometryType: { value: null, matchMode: FilterMatchMode.IN },
    legality: { value: null, matchMode: FilterMatchMode.IN },
    commodity: { value: null, matchMode: FilterMatchMode.EQUALS },
    additionalInfo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    producerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    supplier: { value: null, matchMode: FilterMatchMode.IN },
    treeSpecies: { value: null, matchMode: FilterMatchMode.CONTAINS }
}

export const usePlotStore = defineStore('plot', {
    state: () => ({
        plots: [],
        selectedRow: {},
        selectedPlotOnMap: {},
        isEditDialogShown: false,
        plotToEdit: null,
        isDeleteDialogShown: false,
        currentGeometryMode: GEOMETRY_MODE.NONE,
        originalFeature: {},
        isRiskDetectionSuccessDialogShown: false,
        isRiskDetectionFailureDialogShown: false,
        filters: { ...filters },
        // Pagination
        selectedCheckboxes: [],
        isCurrentPageSelected: false,
        isEveryPageSelected: false,
        selectedPages: [],
        pageParams: {},
        isTableLoading: false,
        totalRecords: 0,
    }),
    actions: {
        resetFilters() {
            this.filters = { ...filters };
        },
        showRiskDetectionSuccessDialog() {
            this.isRiskDetectionSuccessDialogShown = true
        },
        showRiskDetectionFailureDialog() {
            this.isRiskDetectionFailureDialogShown = true
        },
        showEditDialog(plot) {
            this.plotToEdit = cloneDeep(plot);
            this.isEditDialogShown = true;
        },
        hideEditDialog() {
            this.isEditDialogShown = false;
        },
        showDeleteDialog() {
            this.isDeleteDialogShown = true;
        },
        hideDeleteDialog() {
            this.isDeleteDialogShown = false;
        }
    }
})