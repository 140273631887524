import { inject } from "vue";
import { usePlotsStore } from "../../../stores/plots";
import { useAnalyticsAPI } from "../../../composables/useAnalyticsAPI";
import { useBatchStore } from "../../../stores/batch";
import * as Sentry from "@sentry/vue";


export function usePlotsUtils() {
    const { getPaginationPlots } = useAnalyticsAPI();
    const plotsStore = usePlotsStore();
    const batchStore = useBatchStore();
    const map = inject("map");
    const draw = inject("draw");
    const VITE_TILES_SERVICE_EXPRESS_URL = import.meta.env.VITE_TILES_SERVICE_EXPRESS_URL;


    async function refreshData() {
        try {
            draw.deleteAll();
            plotsStore.isTableLoading = true;
            plotsStore.plots = [];
            const limit = plotsStore.pageParams.rows;
            const offset = 0;
            const params = {
                limit: limit,
                offset: offset,
            }
            const response = await getPaginationPlots(params);
            plotsStore.plots = response.plots;
            plotsStore.totalRecords = response.totalRecords;
            batchStore.fetchAllBatches();

            if (map.value && map.value.getSource('all-plots-source')) {
                map.value.getSource('all-plots-source').setTiles([`${VITE_TILES_SERVICE_EXPRESS_URL}/plots/{z}/{x}/{y}.pbf`]);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
        } finally {
            plotsStore.isTableLoading = false;
        }
    }

    return {
        refreshData
    }
}


