import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash';

export const usePlotsStore = defineStore('plots', {
    state: () => ({
        plots: [],
        selectedRow: {},
        selectedPlotOnMap: {},
        isCreateDialogShown: false,
        isEditDialogShown: false,
        plotToEdit: null,
        drawnFeature: {},
        // Pagination
        selectedPages: [],
        pageParams: {},
        isTableLoading: false,
        totalRecords: 0
    }),
    actions: {
        showEditDialog(plot) {
            this.plotToEdit = cloneDeep(plot)
            this.isEditDialogShown = true;
        },
        hideEditDialog() {
            this.plotToEdit = null;
            this.isEditDialogShown = false;
        },
    }
})