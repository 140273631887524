<template>
    <Dialog v-model:visible="supplierStore.isEditDialogShown" modal header="Edit supplier" :closeOnEscape="false"
        :closable="false" :style="{ width: '45rem' }">
        <div class="grid">
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Name <span class="text-red-500">*</span></label>
                <InputText v-model="supplierStore.supplierToEdit.name" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Country <span class="text-red-500">*</span></label>
                <Dropdown v-model="supplierStore.supplierToEdit.country" :options="COUNTRIES" filter />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Commodities</label>
                <MultiSelect v-model="selectedCommodities" :options="COMMODITIES" :maxSelectedLabels="4" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Contact name</label>
                <InputText v-model="supplierStore.supplierToEdit.contact" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Email</label>
                <InputText v-model="supplierStore.supplierToEdit.email" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Phone</label>
                <InputText v-model="supplierStore.supplierToEdit.phone" />
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Address</label>
                <InputText v-model="supplierStore.supplierToEdit.address" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" severity="secondary" class="mr-2" @click="supplierStore.hideEditDialog" />
            <Button label="Save changes" class="light-green-nadar" @click="updateSupplier" :loading="isUpdating" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { COUNTRIES, COMMODITIES } from '../../utils/constants';
import { useSupplierStore } from '../../stores/supplier';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const { patchSupplier } = useAnalyticsAPI();
const toast = useToast();
const supplierStore = useSupplierStore();
const isUpdating = ref(false);

const selectedCommodities = computed({
    get: () => {
        if (supplierStore.supplierToEdit?.commodities) {
            return supplierStore.supplierToEdit.commodities.map(item => item.commodity);
        }
        return null;
    },
    set: (value) => {
        if (value) {
            supplierStore.supplierToEdit.commodities = value.map(commodity => ({ commodity }));
        } else {
            supplierStore.supplierToEdit.commodities = [];
        }
    }
});


function validateSupplierData() {
    let isSomethingMissing = false;

    if (!supplierStore.supplierToEdit.name || supplierStore.supplierToEdit.name.trim().length === 0) {
        isSomethingMissing = true;
    }
    if (!supplierStore.supplierToEdit.country) {
        isSomethingMissing = true;
    }

    // Simple email regex for validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (supplierStore.supplierToEdit.email && !emailPattern.test(supplierStore.supplierToEdit.email.trim())) {
        toast.add({ group: "headless", severity: 'error', summary: 'Invalid Email', detail: "Please enter a valid email address.", life: 3000 });
        return false;
    }

    if (isSomethingMissing) {
        toast.add({ group: "headless", severity: 'info', summary: 'Missing fields', detail: "Please fill out all required fields.", life: 3000 });
        return false;
    }

    return true;
}

function prepareSupplierData() {
    const data = {
        supplierObjectId: supplierStore.supplierToEdit._id,
        name: supplierStore.supplierToEdit.name?.trim(),
        contact: supplierStore.supplierToEdit.contact?.trim(),
        email: supplierStore.supplierToEdit.email?.trim().toLowerCase(),
        phone: supplierStore.supplierToEdit.phone?.trim(),
        country: supplierStore.supplierToEdit.country,
        address: supplierStore.supplierToEdit.address?.trim(),
        commodities: supplierStore.supplierToEdit.commodities || []
    };

    return data;
}

async function updateSupplier() {
    if (!validateSupplierData()) {
        return;
    }

    const data = prepareSupplierData();

    try {
        isUpdating.value = true;
        await patchSupplier(supplierStore.supplierToEdit._id, data);
        toast.add({ group: "headless", severity: 'success', summary: 'Success', detail: 'Supplier successfully updated.', life: 3000 });
        supplierStore.fetchAllSuppliers();
        supplierStore.hideEditDialog();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ group: "headless", severity: 'error', summary: 'Error', detail: 'Failed to update supplier.', life: 3000 });
    } finally {
        isUpdating.value = false;
    }
}
</script>