import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist';
import { firebaseConfig } from "./plugins/firebase"
import { initializeApp } from "firebase/app";
import { registerSW } from 'virtual:pwa-register'
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import App from './App.vue'
import router from './router'

//import "primevue/resources/themes/mira/theme.css"

//import "./assets/themes/aura-light-lime/theme.css";
import "./assets/themes/aura-light-noir/theme.css";
import 'primeicons/primeicons.css';
import "./assets/global.css";

import 'primeflex/primeflex.css';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import InlineMessage from 'primevue/inlinemessage';
import SelectButton from 'primevue/selectbutton';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import Tag from 'primevue/tag';
import Card from 'primevue/card';
import Listbox from 'primevue/listbox';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import MultiSelect from 'primevue/multiselect';
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import Image from 'primevue/image';
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputNumber from 'primevue/inputnumber';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import SplitButton from 'primevue/splitbutton';
import Menu from 'primevue/menu';
import Skeleton from 'primevue/skeleton';
import Divider from 'primevue/divider';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Password from 'primevue/password';
import Timeline from 'primevue/timeline';
import Menubar from 'primevue/menubar';
import Breadcrumb from 'primevue/breadcrumb';


const VITE_DEPLOYMENT_ENV = import.meta.env.VITE_DEPLOYMENT_ENV;
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;


const app = createApp(App);

if (VITE_DEPLOYMENT_ENV === "production") {
    Sentry.init({
        app: app,
        dsn: VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            //Sentry.captureConsoleIntegration()
        ],
        trackComponents: true,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

        tracePropagationTargets: ["localhost", /^https:\/\/(api|ana)\.eudr-solution\.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    /*
    H.init('4g8k7q9g', {
        environment: 'production',
        version: 'commit:abcdefg12345',
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [
                // insert full or partial urls that you don't want to record here
                // Out of the box, Highlight will not record these URLs (they can be safely removed):
                "https://www.googleapis.com/identitytoolkit",
                "https://securetoken.googleapis.com",
            ],
        },
    });
    */
    /*
    var faro = initializeFaro({
        url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/29820e07c2f93eeb3300581cc467fae9',
        app: {
            name: 'EUDR Frontend',
            version: '1.0.0',
            environment: 'production'
        },

        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations(),

            // Initialization of the tracing package.
            // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
            new TracingInstrumentation(),
        ],
    });
    */
}

app.use(PrimeVue);
app.component("Button", Button)
app.component("InputText", InputText)
app.component("Toast", Toast)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("Row", Row)
app.component("Tag", Tag)
app.component("Card", Card)
app.component("Listbox", Listbox)
app.component("Dropdown", Dropdown)
app.component("ProgressSpinner", ProgressSpinner)
app.component("OverlayPanel", OverlayPanel)
app.component("Dialog", Dialog)
app.component("RadioButton", RadioButton)
app.component("MultiSelect", MultiSelect)
app.component("Sidebar", Sidebar)
app.component("Textarea", Textarea)
app.component("Tooltip", Tooltip)
app.component("Image", Image)
app.component("ProgressBar", ProgressBar)
app.component("Message", Message)
app.component("Checkbox", Checkbox)
app.component("Calendar", Calendar)
app.component("IconField", IconField)
app.component("InputIcon", InputIcon)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)
app.component("InputNumber", InputNumber)
app.component("Stepper", Stepper)
app.component("StepperPanel", StepperPanel)
app.component("SplitButton", SplitButton)
app.component("Menu", Menu)
app.component("Skeleton", Skeleton)
app.component("Divider", Divider)
app.component("Badge", Badge)
app.component("Password", Password)
app.component("Timeline", Timeline)
app.component("ColumnGroup", ColumnGroup)
app.component("Menubar", Menubar)
app.component("Breadcrumb", Breadcrumb)
app.component("SelectButton", SelectButton)
app.component("InlineMessage", InlineMessage)

app.directive('styleclass', StyleClass);
app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.use(ToastService);

// Initialize Firebase
initializeApp(firebaseConfig)

const pinia = createPinia();
pinia.use(piniaPersist);
app.use(pinia)
app.use(router)

// https://vite-pwa-org.netlify.app/guide/auto-update#automatic-reload
registerSW({ immediate: true })

app.mount('#app')

/*
Funktioniert nur wenn ich den tab refreshe aber nicht wenn ich live in der platform bin. sollte auch getestet werden wenn ich mich neu auf die platform einlogge also die seite nach 1 tag wieder öffne ob automatisch geupdated wird.
const updateSW = registerSW({
    onNeedRefresh() {
        const userAcceptsUpdate = confirm('A new version of this application is available. Would you like to update now? If you are currently in the middle of a verification check, deforestation check, or importing plots, please choose "Cancel" to avoid any interruptions.');
        if (userAcceptsUpdate) {
            updateSW();
        }
    }
});
*/